.m-navMain {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	&_item {
		position: relative;
		display: block;
		margin-bottom: $sm-value * 0.5;
		white-space: nowrap;
		transform: translateY(-$sm-value);
		opacity: 0;
		transition: opacity $fast-duration, transform 0s $fast-duration;

		@include breakpoint(large) {
			margin-bottom: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&:hover,
		&:focus {
			.m-navMain_arrow {
				&::before {
					@include breakpoint(xlarge) {
						transform: scale(1);
						transition: transform $duration $easeOutBack;
					}
				}

				svg {
					@include breakpoint(xlarge) {
						@include fallback(fill, $primary);
						transform: translate(0, 0);
					}
				}
			}
		}

		&.-activeTrail {
			pointer-events: none;

			& > .m-navMain_link {
				text-decoration: underline;
			}
		}
	}

	&_link {
		@include fluid-type(320px, $grid-container, 24px, 47px);
		@include fallback(color, $secondary);
		display: inline-block;
		padding-right: 25px;
		font-weight: 500;
		text-decoration: none;
		transition: color $duration;

		@include breakpoint(large) {
			padding-top: $xs-value;
			padding-right: $sm-value;
			padding-bottom: $xs-value;

			@media screen and (max-height: 640px) {
				@include fluid-type(320px, $grid-container, 24px, 32px);
				padding-top: 8px;
				padding-bottom: 8px;
			}
		}
	}

	&_arrow {
		position: absolute;
		top: 0;
		right: 0;
		width: 22px;
		height: 22px;
		pointer-events: none;

		@include breakpoint(large) {
			top: $xxs-value;
		}

		&::before {
			@include breakpoint(xlarge) {
				position: absolute;
				border-radius: 50%;
				background: $secondary;
				transform: scale(0);
				transition: transform $faster-duration;
				content: "";
				inset: 0;
			}
		}

		svg {
			@include fallback(fill, $secondary);
			position: absolute;
			top: 6px;
			left: 6px;
			display: block;
			width: 8px;
			height: 8px;
			transition: fill $duration, transform $duration;

			@include breakpoint(xlarge) {
				width: 10px;
				height: 10px;
				transform: translate(-75%, 75%);
			}
		}
	}

	&_sub {
		display: none;
	}
}

.o-body.-menuOn {
	.m-navMain_item {
		transform: translateY(0);
		opacity: 1;
		transition: transform $duration $duration, opacity $duration $duration;

		@for $i from 1 through 5 {
			&:nth-child(#{$i}) {
				transition: transform $duration #{0.32 + $i * 0.045}s, opacity $duration #{0.32 + $i * 0.045}s;
				transition-timing-function: $easeOutCubic;
			}
		}
	}
}

.o-body.-ecoMode {
	.m-navMain_item:hover,
	.m-navMain_item:focus {
		.m-navMain_arrow svg {
			@include breakpoint(xlarge) {
				@include fallback(fill, $secondary);
			}
		}
	}

	.m-navMain_link {
		@include fallback(color, $primary);
	}

	.m-navMain_arrow {
		&::before {
			@include breakpoint(xlarge) {
				background: $primary;
			}
		}

		svg {
			@include fallback(fill, $primary);
		}
	}
}
