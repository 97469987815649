.m-summary {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: $s-value;

	@include breakpoint(large) {
		margin-top: $m-value;
	}

	&_anchor {
		@include fluid-type(1024px, $grid-container, 14px, 18.5px);
		position: relative;
		margin-bottom: $xs-value;
		font-weight: 500;
		color: $primary;
		line-height: 1.3em;
		text-decoration: none;

		@include breakpoint(large) {
			padding-left: $m-value;
			margin-bottom: $sm-value * 0.5;
			opacity: 0.7;
			transition: opacity $faster-duration, color $faster-duration;
		}

		&::before {
			@include breakpoint(large) {
				position: absolute;
				top: 50%;
				left: 0;
				width: 25px;
				height: 2px;
				background: $tertiary;
				transform: translateY(-50%) scaleX(0);
				transform-origin: left;
				transition: transform $faster-duration;
				content: "";
			}
		}

		&::after {
			@include breakpoint(large) {
				position: absolute;
				top: 50%;
				left: 25px;
				width: 2px;
				height: 10px;
				background: $tertiary;
				transform: translateY(-50%) scaleY(0);
				transform-origin: center;
				transition: transform $faster-duration;
				content: "";
			}
		}

		&:last-child {
			margin-bottom: 0;
		}

		&:hover {
			@include breakpoint(large) {
				opacity: 1;
			}
		}

		&.-active {
			@include breakpoint(large) {
				color: $tertiary;
				opacity: 1;
			}

			&::before {
				@include breakpoint(large) {
					transform: translateY(-50%) scaleX(1);
				}
			}

			&::after {
				@include breakpoint(large) {
					transform: translateY(-50%) scaleY(1);
					transition: transform $faster-duration $faster-duration;
				}
			}
		}
	}
}
