$cursor-size: 96px;
$squared-width: 200px;
$squared-height: 250px;
$offset: 40px;

.advancedPointer {
	display: none;

	@include breakpoint(large) {
		position: absolute;
		z-index: 2;
		display: block;
		overflow: hidden;
		pointer-events: none;
		inset: 0;
	}

	&.-active {
		.advancedPointer_pointer {
			@include breakpoint(large) {
				transform: scale(1);
				opacity: 1;
				transition: transform 0.5s $easeOutBack;
			}
		}

		.advancedPointer_label {
			@include breakpoint(large) {
				opacity: 1;
				transition: opacity 0.3s 0.2s;
			}
		}

		.advancedPointer_svg {
			@include breakpoint(large) {
				opacity: 1;
				transition: opacity 0.3s 0.2s;
			}
		}
	}

	&_container {
		@include breakpoint(large) {
			position: absolute;
			top: $cursor-size * -0.5;
			left: $cursor-size * -0.5;
			z-index: 1;
			width: $cursor-size;
			height: $cursor-size;
			pointer-events: none;
			will-change: transform;
		}

		&.-primary {
			.advancedPointer_pointer {
				@include breakpoint(large) {
					background: $primary;
				}
			}

			.advancedPointer_svg {
				@include breakpoint(large) {
					stroke: $secondary;
				}
			}

			.advancedPointer_label {
				@include breakpoint(large) {
					color: $secondary;
				}
			}
		}

		&.-right,
		&.-left {
			.advancedPointer_svg {
				@include breakpoint(large) {
					width: 32px;
					height: 31px;
				}
			}
		}

		&.-squared {
			@include breakpoint(large) {
				top: $squared-height * -0.5;
				left: $squared-width * -0.5;
				width: $squared-width;
				height: $squared-height;
			}

			.advancedPointer_pointer {
				@include breakpoint(large) {
					border-radius: 0;
				}
			}
		}

		&.-notLoaded {
			.advancedPointer_loaderTrigger {
				@include breakpoint(large) {
					opacity: 1;
					transition: opacity 0s;
				}

				&::before {
					@include breakpoint(large) {
						transform: scale(1);
						opacity: 1;
						transition: transform 0.5s $easeOutBack;
					}
				}
			}

			.advancedPointer_loaderLabel {
				@include breakpoint(large) {
					opacity: 1;
					transition: opacity 0.3s 0.2s;
				}
			}
		}
	}

	&_pointer {
		@include breakpoint(large) {
			position: absolute;
			top: 0;
			left: 0;
			overflow: hidden;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			background: $secondary;
			transform: scale(0);
			opacity: 0;
			transition: transform 0.3s, opacity 0s 0.3s;
		}

		img {
			@include breakpoint(large) {
				position: absolute;
				top: 0;
				left: 0;
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}

	&_label {
		@include breakpoint(large) {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 80%;
			font-weight: 700;
			font-size: 14px;
			color: $primary;
			line-height: 1.15;
			text-align: center;
			text-decoration: underline;
			transform: translate(-50%, -50%) rotate(-20deg);
			opacity: 0;
			transition: opacity 0.2s;
		}
	}

	&_canvas {
		@include breakpoint(large) {
			width: 100%;
		}
	}

	&_svg {
		@include breakpoint(large) {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 25px;
			height: 25px;
			transform: translate(-50%, -50%);
			opacity: 0;
			transition: opacity 0.2s;
			stroke: $primary;
			stroke-width: 1px;
		}
	}

	&_loaderTrigger {
		@include breakpoint(large) {
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 1;
			box-sizing: border-box;
			width: $cursor-size;
			height: $cursor-size;
			padding: $s-value;
			border: none;
			background: transparent;
			transform: translate(-50%, -50%);
			opacity: 0;
			transition: opacity 0s 0.3s;
			pointer-events: none;
		}

		&::before {
			@include breakpoint(large) {
				position: absolute;
				z-index: -1;
				border-radius: 50%;
				background: $primary;
				transform: scale(0);
				transition: transform 0.3s;
				content: "";
				inset: 0;
			}
		}
	}

	&_loaderLabel {
		@include breakpoint(large) {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 80%;
			font-weight: 700;
			font-size: 14px;
			font-family: $font-family;
			color: $secondary;
			line-height: 1.15;
			text-align: center;
			text-decoration: underline;
			transform: translate(-50%, -50%) rotate(-20deg);
			opacity: 0;
			transition: opacity 0.2s;
		}
	}
}
