.a-burger {
	position: relative;
	width: 18px;
	height: 18px;
	margin-right: $s-value;

	@include breakpoint(xlarge) {
		display: none;
	}

	&_open {
		position: absolute;
		top: 50%;
		width: 100%;
		height: 55%;
		transform: translateY(-50%);
		transition: transform $fast-duration;

		&::before,
		&::after {
			position: absolute;
			top: 0;
			width: 100%;
			height: 2px;
			background: #fff;
			content: "";
		}

		&::after {
			top: auto;
			bottom: 0;
		}
	}

	&_close {
		position: absolute;
		width: 100%;
		height: 100%;
		transform: scale(0) rotate(-45deg);
		transition: transform $fast-duration;

		&::before,
		&::after {
			position: absolute;
			top: 50%;
			left: -5%;
			width: 110%;
			height: 2px;
			background: #fff;
			transform: translateY(-50%) rotate(45deg);
			content: "";
		}

		&::after {
			transform: translateY(-50%) rotate(-45deg);
		}
	}
}

.-menuOn {
	.a-burger_open {
		transform: translateY(-50%) scale(0) rotate(45deg);
	}

	.a-burger_close {
		transform: scale(1) rotate(0);
	}
}
