.m-listCustomerCases {
	display: flex;
	flex-flow: row wrap;

	@include breakpoint(large) {
		position: relative;
		margin-bottom: -$m-value;
	}

	&_customer {
		position: relative;
		width: 100%;
		margin-bottom: $m-value;

		@include breakpoint(large) {
			width: 50%;
		}

		&:hover,
		&:focus {
			.m-listCustomerCases_visual img.loaded {
				@include breakpoint(large) {
					transform: scale(1.1);
				}
			}

			.m-listCustomerCases_details {
				@include breakpoint(large) {
					clip-path: inset(0% 0% 0% 0%);
				}
			}

			.m-listCustomerCases_title {
				@include breakpoint(large) {
					background-size: 100% 0.1em;
				}
			}
		}

		&:last-child {
			margin-bottom: 0;

			@include breakpoint(large) {
				margin-bottom: $m-value;
			}
		}

		&.-hovered {
			.m-listCustomerCases_visual img.loaded {
				transform: scale(1.1);
			}

			.m-listCustomerCases_details {
				clip-path: inset(0% 0% 0% 0%);
			}
		}
	}

	&_visual {
		position: relative;
		overflow: hidden;
		margin-bottom: $xs-value;
		pointer-events: none;
		inset: 0;

		img {
			transition: transform $duration $easeInOutCubic;
		}
	}

	&_details {
		position: absolute;
		top: 50%;
		right: 0;
		left: 0;
		display: flex;
		padding: $xs-value 0;
		white-space: nowrap;
		background: $primary;
		transform: translateY(-50%);
		transition: clip-path $duration $easeInOutCubic;
		clip-path: inset(50% 0% 50% 0%);
	}

	&_detailsScroll {
		display: flex;

		span {
			position: relative;
			padding-right: 33px;
			color: $secondary;

			@include breakpoint(large) {
				padding-right: 47px;
			}

			&::after {
				position: absolute;
				top: 50%;
				right: 10px;
				width: 13px;
				height: 1px;
				background: $secondary;
				content: "";

				@include breakpoint(large) {
					right: 15px;
					width: 17px;
				}
			}
		}
	}

	&_content {
		// position: absolute;
		// right: 0;
		// bottom: 0;
		// left: 0;
		// padding: $s-value $grid-margin-gutters * 0.5;
		// background: $primary;
		// transform: translateY(100%);
		// transition: transform $duration;

		@include breakpoint(small) {
			right: $s-value;
			left: $s-value;
		}

		@include breakpoint(large) {
			top: auto;
			right: $sm-value;
			bottom: 175px * 0.5; // clip path height / 2
			left: $sm-value;
			text-align: center;
			background: transparent;
			transform: translateY(calc(50% + #{$s-value}));
			opacity: 0;
			transition: transform 0s $fast-duration, opacity $fast-duration;
		}
	}

	&_title {
		@include fluid-type(320px, $grid-container, 14px, 20px);
		display: inline-block;
		// margin-bottom: $xs-value;
		// font-weight: 500;
		// color: $secondary;
		padding-right: $xs-value + $grid-margin-gutters * 0.5;

		@include breakpoint(large) {
			@include text-stroke(1, $secondary);
			padding-right: 0;
			background: linear-gradient(to right, $primary, $primary);
			background-repeat: no-repeat;
			background-position: 100% 95%;
			background-size: 100% 0;
			transition: background-size $fast-duration;
		}

		span {
			&::after {
				display: inline;
				content: " x ";
			}

			&:last-child {
				&::after {
					display: none;
				}
			}
		}
	}

	&_legend {
		color: $secondary;
	}

	&_link {
		position: absolute;
		inset: 0;
	}
}

.o-body.-eco {
	.m-listCustomerCases_details {
		display: none;
	}
}
