.m-navLegal {
	@include breakpoint(large) {
		text-align: center;
	}

	&_item {
		margin-bottom: $xs-value;
		text-align: center;

		@include breakpoint(large) {
			display: inline-block;
			margin-right: $s-value;
			margin-bottom: 0;
		}

		&:last-child {
			margin-bottom: 0;

			@include breakpoint(large) {
				margin-right: 0;
			}
		}

		&.-activeTrail {
			pointer-events: none;

			& > .m-navLegal_link {
				text-decoration: underline;
			}
		}
	}

	&_link {
		@include fluid-type(320px, $grid-container, 13px, 17.5px);
		color: $primary;
		text-decoration: none;

		&:hover,
		&:focus {
			@include breakpoint(xlarge) {
				text-decoration: underline;
			}
		}
	}
}
