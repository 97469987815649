.m-logos {
	@include xy-grid;
	overflow: visible;

	@include breakpoint(large) {
		margin-bottom: $grid-margin-gutters * -1;
	}

	&_logo {
		@include xy-cell(6);
		position: relative;
		margin: 0;
		text-align: center;

		@include breakpoint(medium) {
			@include xy-cell(4);
		}

		@include breakpoint(large) {
			@include xy-cell(3);
			margin-bottom: $grid-margin-gutters;
		}
	}

	img {
		display: inline-block;
		margin-right: auto;
		margin-bottom: $s-value;
		margin-left: auto;
	}

	&_link {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}
