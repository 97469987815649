.m-blockquote {
	@include xy-grid;
	align-items: stretch;
	margin-right: $grid-margin-gutters * -0.5;
	margin-left: $grid-margin-gutters * -0.5;

	&_content {
		@include xy-cell(10);
		position: relative;
		padding-top: $l-value;
		padding-bottom: $l-value;

		@include breakpoint(large) {
			@include xy-cell(9);
			box-sizing: border-box;
			padding: 100px $xl-value;
			background-image: linear-gradient(to bottom, transparent 50%, $secondary 50%);
		}

		@include breakpoint(xxlarge) {
			padding: $xxl-value $xl-value;
		}

		@include breakpoint(xxxlarge) {
			padding: $xxl-value 100px;
		}

		&::before {
			@include breakpoint(large) {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				width: 1px;
				background: $quaternary;
				content: "";
			}
		}

		&::after {
			position: absolute;
			top: 0;
			right: $grid-margin-gutters * -0.5;
			bottom: 0;
			width: 1px;
			background: $quaternary;
			content: "";

			@include breakpoint(large) {
				right: 0;
			}
		}
	}

	&_right {
		@include xy-cell(2);
		position: relative;

		@include breakpoint(large) {
			@include xy-cell(1);
		}

		&::after {
			@include breakpoint(large) {
				position: absolute;
				top: 1px;
				right: -$grid-margin-gutters;
				bottom: 1px;
				width: 1px;
				background: $quaternary;
				content: "";
			}
		}
	}

	&_liftContainer {
		position: absolute;
		top: 1px;
		right: -$grid-margin-gutters * 0.5;
		bottom: 1px;
		left: -$grid-margin-gutters * 0.5;
		box-sizing: border-box;
		overflow: hidden;

		@include breakpoint(small) {
			right: -$xs-value - $grid-margin-gutters * 0.5;
		}

		@include breakpoint(medium) {
			right: -$grid-margin-gutters;
		}

		@include breakpoint(large) {
			right: -$grid-margin-gutters;
			left: -$grid-margin-gutters;
		}
	}

	&_lift {
		overflow: hidden;

		&.-primary {
			svg {
				fill: $primary;
			}
		}

		svg {
			display: block;
			margin-bottom: $sm-value;
			fill: $tertiary;

			@include breakpoint(large) {
				margin-bottom: $l-value;
			}

			// @include breakpoint(xxlarge) {
			// 	margin-bottom: $xl-value;
			// }
		}
	}

	&_author {
		margin-top: $s-value;

		@include breakpoint(large) {
			margin-top: $sm-value;
		}
	}
}
