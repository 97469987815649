.a-loader {
	position: fixed;
	z-index: 3;
	visibility: hidden;
	pointer-events: none;
	inset: 0;

	&_background {
		position: absolute;
		background: $secondary;
		will-change: transform;
		inset: 0;
	}

	&_visualsContainer {
		position: absolute;
		inset: 0;
	}

	picture {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 144px;
		transform: translate(-50%, -50%);
		clip-path: inset(100% 0% 0% 0%);

		@include breakpoint(large) {
			width: 200px;
		}

		@include breakpoint(xxlarge) {
			width: 250px;
		}
	}

	svg {
		@include fallback(fill, $primary);
		position: absolute;
		top: 50%;
		left: 50%;
		width: 120px;
		transform: translate(-50%, -50%);

		@include breakpoint(large) {
			width: 160px;
		}
	}
}
