body.cke_editable {
	@include fontSize(14px);
	margin: $s-value !important;
	font-family: $font-family;
	color: $black;
	background-color: $white;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;

	@include breakpoint(xlarge) {
		@include fontSize(16px);
	}

	.a-heading {
		margin-bottom: 0.5em;
		color: $black;

		&.-h2 {
			@include fontSize(26px);
			color: $tertiary-fallback;
		}

		&.-h3 {
			@include fontSize(22px);
		}

		&.-h4 {
			@include fontSize(18px);
		}
	}

	img,
	picture {
		width: 100%;
		height: auto;
	}

	img {
		max-width: 800px;
		margin-bottom: $s-value;
	}

	figure {
		margin-bottom: $s-value;

		img {
			margin-bottom: 0;
		}
	}

	strong {
		font-weight: 600;
	}

	em {
		font-style: italic;
	}

	iframe {
		max-width: 100%;
		border-radius: 25px;
		margin: 30px auto;
	}

	p {
		margin-bottom: $s-value;
		line-height: 1.5em;
	}

	ul,
	ol {
		margin-bottom: 1.25em;
		margin-left: 1.25em;
	}

	ul {
		list-style-type: square;
	}

	ol {
		list-style-type: decimal;
	}

	li {
		margin-bottom: $xxs-value;
	}

	a:not(.a-btn) {
		color: $black;
	}

	blockquote {
		position: relative;
		width: 90%;
		margin: 0 auto;
		color: $black;
		text-align: center;

		@include breakpoint(small) {
			width: 80%;
		}

		@include breakpoint(large) {
			&::before {
				position: absolute;
				top: 50%;
				left: 50%;
				z-index: -1;
				box-sizing: border-box;
				width: 360px;
				height: 360px;
				border: 1px solid $tertiary-fallback;
				border-radius: 100%;
				transform: translate(-50%, -50%);
				content: "";
			}
		}

		p {
			@include fontSize($s-value + 8);
			max-width: 30ch;
			padding-right: $m-value;
			padding-left: $m-value;
			margin: $l-value auto;
			line-height: 1.5em;

			@include breakpoint(large) {
				margin: $xxl-value + $m-value auto;
			}

			&::before,
			&::after {
				@include fontSize(60px);
				position: absolute;
				width: $m-value;
				height: $m-value;
				color: $tertiary-fallback;
			}
		}
	}
}
