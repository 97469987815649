@use "sass:math";

.m-cards {
	@include xy-grid;
	overflow: visible;

	@include breakpoint(large) {
		justify-content: center;
		margin-right: math.div($grid-margin-gutters, -2);
		margin-bottom: $m-value * -1;
		margin-left: math.div($grid-margin-gutters, -2);
	}

	&_card {
		@include xy-cell(90%);
		box-sizing: border-box;
		height: auto;
		padding: $grid-margin-gutters $s-value;
		margin: 0;
		text-align: center;
		background: $grey;

		@include breakpoint(medium) {
			@include xy-cell(6);
			margin: 0;
			text-align: center;
		}

		@include breakpoint(large) {
			@include xy-cell(4);
			margin-bottom: $m-value;
		}

		@include breakpoint(xlarge) {
			@include xy-cell(3);
		}
	}

	img {
		display: inline-block;
		width: 160px;
		height: 160px;
		margin-bottom: $s-value;
	}
}
