.a-switch {
	position: relative;
	opacity: 0;
	transition: opacity $fast-duration;

	@include breakpoint(medium) {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	&_title {
		@include fluid-type(320px, $grid-container, 13px, 18px);
		position: relative;
		display: block;
		width: 64px;
		margin-bottom: $xxs-value;
		margin-left: auto;
		font-weight: 500;
		color: $secondary;
		text-align: center;
		transition: color $duration;

		@include breakpoint(medium) {
			order: 2;
			width: auto;
			margin-bottom: 0;
			margin-left: $sm-value * 0.5;
		}

		@include breakpoint(xxlarge) {
			margin-left: 18px;
		}
	}

	&_checkbox {
		position: absolute;
		bottom: 0;
		visibility: hidden;

		&:checked + .a-switch_label {
			background: $secondary;

			&::before {
				background: $primary;
				transform: translateX(90%) translateY(-50%);
			}
		}

		&:checked + .a-switch_title {
			text-decoration: line-through;
		}
	}

	&_label {
		position: relative;
		display: block;
		box-sizing: border-box;
		width: 64px;
		height: 30px;
		border: 1px solid $secondary;
		border-radius: 50%;
		margin-left: auto;
		transition: background-color $duration, border-color $duration;

		&::before {
			position: absolute;
			top: 50%;
			left: calc(50% - 8px);
			width: 16px;
			height: 16px;
			border-radius: 50%;
			background: $secondary;
			transform: translateX(-90%) translateY(-50%);
			transition: transform $duration $easeOutBack, background-color $duration;
			content: "";
		}

		&:hover,
		&:focus {
			@include breakpoint(xlarge) {
				cursor: pointer;
			}
		}
	}
}

.o-body.-menuOn {
	.a-switch {
		opacity: 1;
		transition: opacity $duration $long-duration;
	}
}

.o-body.-ecoMode {
	.a-switch {
		&_title {
			color: $primary;
		}

		&_label {
			border: 1px solid $primary;

			&::before {
				background: $primary;
			}
		}
	}
}

// TODO petit thumb up qui vole vers le haut quand activé 🙏🌍
