.a-share {
	@include fluid-type(1024px, $grid-container, 14px, 18.5px);
	position: relative;
	display: inline-block;
	padding: 0;
	border: none;
	margin-top: $sm-value;
	font-weight: 500;
	font-family: $font-family;
	color: $tertiary;
	text-decoration: underline;
	background: transparent;
	cursor: pointer;
	appearance: none;

	@include breakpoint(large) {
		margin-top: $m-value;
	}

	@include breakpoint(xlarge) {
		padding-left: $m-value;
	}

	&:first-child {
		margin-top: 0;
	}

	svg {
		display: inline-block;
		width: 16px;
		height: 16px;
		margin-left: $xxs-value;
		transform: translateY(3px);
		stroke: $tertiary;

		@include breakpoint(xlarge) {
			width: 18px;
			height: 19px;
			transform: translateY(6px);
		}
	}

	&_tip {
		@include fluid-type(1024px, $grid-container, 11px, 13px);
		position: absolute;
		right: 0;
		bottom: -$xs-value;
		padding: $xxs-value;
		border-radius: 2px;
		color: $secondary;
		background: $primary;
		transform: translateY(100%);
		opacity: 0;
		pointer-events: none;

		&::before {
			position: absolute;
			top: 0;
			right: 4px;
			width: 8px;
			height: 8px;
			background: $primary;
			transform: translateX(-50%) translateY(-50%) rotate(45deg);
			content: "";
		}
	}
}
