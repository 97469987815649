.m-listExpertises {
	$border-width: 1px;
	$border-color: $quaternary;
	$text-color: $primary;

	width: 100%;

	@include breakpoint(large) {
		background-color: $secondary;
	}

	&_item {
		padding: $s-value;

		@include breakpoint(large) {
			$gap: -0.5 * $grid-margin-gutters;

			@include xy-grid;

			position: relative;
			//margin-right: $gap;
			//margin-left: $gap;
			border: $border-width solid $border-color;
			border-right: none;
			padding: $m-value;
		}

		&::after {
			@include breakpoint(large) {
				position: absolute;
				content: "";
				display: block;
				width: calc(100vw - 100%);
				bottom: -$border-width;
				height: $border-width;
				right: 0;
				transform: translateX(100%);
				background-color: $border-color;
			}
		}

		& + & {
			@include breakpoint(large) {
				border-top: none;
			}
		}

		&:nth-child(even) {
			.m-listExpertises_num {
				color: $text-color;
			}
		}
	}

	&_num {
		$stroke: $border-width $text-color;

		color: transparent;
		-webkit-text-stroke: $stroke;
		text-stroke: $stroke;

		@include breakpoint(large) {
			@include xy-cell(2 of 9);
		}
	}

	&_text {
		@include breakpoint(large) {
			@include xy-cell(7 of 9);
		}

		.a-heading {
			text-transform: uppercase;
			margin-bottom: 1.5 * $xs-value;
		}
	}
}
