@use "sass:math";

/// Block mixin to apply styles to general form fields (simple inputs, selects, text areas, etc...)
@mixin forFields {
	input[type="email"],
	input[type="number"],
	input[type="password"],
	input[type="search"],
	input[type="text"],
	input[type="tel"],
	select,
	textarea {
		@content;
	}
}

/*
//Browsers
ie,
edge,
chrome,
opera,
firefox,
safari,
vivaldi,

//Specific Browsers
chromeIOS,
ieMobile,

//Platforms
windows,
mac,
linux,
android,
blackberry,
ios,

//Type
desktop,
mobile,
*/

/// Block mixin that adds styles for the given browser
/// @param {string|keyword} $browsers - The browser(s) combination(s) to style for
/// @content Styles that will be applied only if the browser matches the provided one
/// @example scss - Browser mixin
/// 	@include browser(ie){}
/// 	// styles only IE
///
/// 	@include browser("desktop.windows"){}
/// 	// styles only on desktop versions of windows
@mixin browser($browsers...) {
	$selector: &;

	@each $b in $browsers {
		$browser: unquote(quote($b)); // Trick to be able to pass class combos

		/*
		Allows:
			browser(ie)
			browser("ie.mobile")
			browser("firefox.android")
			browser(ie, "firefox.ios")
			etc...
		*/

		@at-root {
			html.#{$browser} {
				#{$selector} {
					@content;
				}
			}
		}
	}
}

@function strip-unit($value) {
	@return math.div($value, ($value * 0 + 1));
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
	$u1: unit($min-vw);
	$u2: unit($max-vw);
	$u3: unit($min-font-size);
	$u4: unit($max-font-size);

	@if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
		& {
			font-size: $min-font-size;

			@media screen and (min-width: $min-vw) {
				font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
			}

			@media screen and (min-width: $max-vw) {
				font-size: $max-font-size;
			}
		}
	}
}

// the variable mixin takes a property and variable name
@mixin fallback($prop, $var) {
	@if $var == $primary {
		#{$prop}: $primary-fallback;
	}

	@else if $var == $secondary {
		#{$prop}: $secondary-fallback;
	}
	#{$prop}: $var;
}

// Text stroke
@function text-stroke($size: 2, $color: #fff, $correction: 0) {
	$size-rounded: round($size);
	$size-rest: $size-rounded - $size;

	$radius: $size - $correction;
	$stroke: ();

	@for $i from -$size-rounded through $size-rounded {
		$i: $i + $size-rest;

		@for $k from -$size-rounded through $size-rounded {
			$k: $k + $size-rest;

			$x: $k;
			$y: $i;

			@if $k > 0 {
				$x: $k - 0.5;
			}

			@else if $k < 0 {
				$x: $k + 0.5;
			}

			@if $i > 0 {
				$y: $i - 0.5;
			}

			@else if $i < 0 {
				$y: $i + 0.5;
			}

			@if ($x*$x + $y*$y <= $radius*$radius) {
				$stroke: append($stroke, $i*1px $k*1px 0 $color, comma);
			}
		}
	}

	@return $stroke;
}

@mixin text-stroke($size: 2, $color: #fff, $correction: 0) {
	text-shadow: text-stroke($size, $color, $correction);
}
