.m-navFooter {
	&_item,
	&_subItem {
		margin-bottom: $xs-value;

		@include breakpoint(large) {
			margin-bottom: 12px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&_link,
	&_subLink {
		@include fluid-type(320px, $grid-container, 14px, 20px);
		font-weight: 500;
		color: $primary;
		line-height: 1.1em;
		text-decoration: none;

		@include breakpoint(xlarge) {
			transition: color $faster-duration;
		}

		&:hover,
		&:focus {
			@include breakpoint(xlarge) {
				text-decoration: underline;
			}
		}

		&.is-active {
			text-decoration: underline;
			pointer-events: none;
		}
	}

	&_sub {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-top: $xs-value;
	}

	&_subItem {
		position: relative;
	}

	&_subLink {
		font-weight: 400;
	}

	&_notification {
		@include fontSize(9px);
		position: absolute;
		right: -$xxs-value;
		bottom: 0.15em;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		font-weight: 500;
		color: $secondary;
		line-height: 12px;
		text-align: center;
		background: $primary;
		transform: translateX(100%);

		@include breakpoint(large) {
			@include fontSize(12px);
			right: -$xs-value;
			width: auto;
			height: 18px;
			padding: 0 $xxs-value 0 $xs-value;
			border-radius: 9px;
			color: transparent;
			line-height: 18px;
		}

		&::before {
			@include breakpoint(large) {
				display: inline;
				color: $secondary;
				content: "we're hiring";
			}
		}
	}
}
