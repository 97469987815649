.o-block {
	margin-top: $xl-value + $s-value;
	margin-bottom: $xl-value + $s-value;

	@include breakpoint(large) {
		margin-top: $xxl-value + $xl-value;
		margin-bottom: $xxl-value + $xl-value;
	}

	&.-textsImage.-lift {
		.o-block_container {
			@include breakpoint(large) {
				padding-top: $xxl-value;
				padding-bottom: $xxl-value;
			}

			@include breakpoint(xxlarge) {
				padding-top: $xxl-value + $s-value;
				padding-bottom: $xxl-value + $s-value;
			}
		}
	}

	&.-sliderCustomer,
	&.-actionsBcorp,
	&.-viewFolders,
	&.-listExpertises {
		@include breakpoint(large) {
			position: relative;
			// overflow: hidden;
			// border-top: 1px solid $primary;
			// border-bottom: 1px solid $primary;
		}

		&::before,
		&::after {
			display: none;

			@include breakpoint(large) {
				display: block;
			}
		}

		.o-block_title {
			@include breakpoint(large) {
				position: absolute;
				top: $xl-value;
			}
		}
	}

	&.-newsletter {
		margin-bottom: 0;

		@include breakpoint(large) {
			margin-top: 0;
			margin-bottom: $xxl-value + $xl-value;
		}

		// &::before {
		// 	@include breakpoint(large) {
		// 		display: none;
		// 	}
		// }

		&::after {
			display: none;

			@include breakpoint(large) {
				display: block;
			}
		}
	}

	&.-viewOffers,
	&.-viewCustomerCases,
	&.-agencies {
		position: relative;

		.o-block_container {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;

			@include breakpoint(xlarge) {
				left: 50%;
				width: calc(100% - 240px);
				margin: 0;
				transform: translateX(-50%);
			}
		}
	}

	&.-viewArticles {
		@include breakpoint(large) {
			margin-bottom: 0;
		}

		.o-block_right {
			margin-bottom: 0;
		}

		&.-folder {
			@include breakpoint(large) {
				margin-top: $xxl-value;
			}
		}
	}

	&.-viewFolders {
		@include breakpoint(large) {
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	&.-article {
		position: relative;
		padding-bottom: $l-value;
		margin-top: 0;
		margin-bottom: 0;

		@include breakpoint(large) {
			position: relative;
			padding-top: $xl-value;
			padding-bottom: $xl-value + $sm-value;
			// margin-top: $xxl-value;
			background-image: linear-gradient(to right, transparent 50%, $secondary 50%);
		}

		@include breakpoint(xxlarge) {
			padding-top: $xxl-value;
			padding-bottom: $xxl-value + $sm-value;
		}

		&::before {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			height: 1px;
			background: $quaternary;
			content: "";
		}

		.o-block_title {
			margin-top: $l-value;
			margin-bottom: $xl-value;

			@include breakpoint(large) {
				margin-top: 0;
				margin-bottom: 0;
			}
		}

		.a-text {
			// & > :first-child:not(.a-heading, section) {
			// 	@include breakpoint(large down) {
			// 		margin-top: $m-value;
			// 	}
			// }

			.a-heading {
				&.-h2 {
					@include fallback(color, $tertiary);
				}
			}
		}
	}

	&.-relateds {
		position: relative;

		@include breakpoint(large) {
			margin-top: $xxl-value;
			margin-bottom: $xxl-value;
		}

		&::before {
			position: absolute;
			top: -($xl-value + $s-value) - 0.5px;
			right: 0;
			left: 0;
			height: 1px;
			background: $quaternary;
			content: "";

			@include breakpoint(large) {
				top: -$xxl-value - 0.5px;
			}
		}
	}

	&.-survey {
		border-top: 1px solid $quaternary;
	}

	&.-full {
		box-sizing: border-box;
		padding-top: $xl-value;
		padding-bottom: $xl-value;

		@include breakpoint(large) {
			padding-top: $xl-value * 2;
			padding-bottom: $xl-value * 2;
		}

		&.-noTop {
			padding-top: $m-value;

			@include breakpoint(large) {
				padding-top: $xl-value;
			}
		}

		&.-noBot {
			padding-bottom: $m-value;

			@include breakpoint(large) {
				padding-bottom: $xl-value;
			}
		}
	}

	&.-blockquote,
	&.-newsletter {
		background: $secondary;
	}

	&.-listExpertises {
		overflow: hidden;

		.o-block_container {
			&::after {
				@include breakpoint(large) {
					position: absolute;
					bottom: 0;
					left: 0;
					display: block;
					width: 100%;
					height: 1px;
					background-color: $quaternary;
					transform: translateX(-50%);
					content: "";
				}
			}
		}

		.o-block_title {
			position: unset;
			top: unset;
		}
	}

	&.-bordered {
		position: relative;

		@include breakpoint(large) {
			margin-top: 0;
			margin-bottom: 0;
		}

		&::before,
		&::after {
			position: absolute;
			top: -0.5px;
			right: 0;
			left: 0;
			height: 1px;
			background: $quaternary;
			content: "";

			@include breakpoint(large) {
				z-index: 1;
			}
		}

		&::after {
			top: auto;
			bottom: -0.5px;
		}

		// &.-noTop {
		// 	&::before {
		// 		@include breakpoint(large) {
		// 			display: none;
		// 		}
		// 	}
		// }

		&.-noBot {
			&::after {
				display: none;
			}
		}
	}

	&.-borderedDesktop {
		position: relative;

		@include breakpoint(large) {
			margin-top: 0;
			margin-bottom: 0;
		}

		&::before,
		&::after {
			@include breakpoint(large) {
				position: absolute;
				top: -0.5px;
				right: 0;
				left: 0;
				height: 1px;
				background: $quaternary;
				content: "";
			}
		}

		&::after {
			@include breakpoint(large) {
				top: auto;
				bottom: -0.5px;
			}
		}
	}

	&.-noTop {
		margin-top: 0;
	}

	&.-noBot {
		margin-bottom: 0;
	}

	&.-commercialHook {
		$img-size: 140px;

		text-align: center;

		@include breakpoint(medium) {
			text-align: left;
		}

		.o-block_text {
			@include breakpoint(medium) {
				display: flex;
			}
		}

		.media {
			flex: 0 0 $img-size;
			width: $img-size;
			height: $img-size;
			border-radius: 2 * $img-size;
			margin: 0 auto $s-value auto;

			@include breakpoint(medium) {
				margin-right: $s-value;
				margin-bottom: 0;
			}

			article,
			picture,
			img {
				display: block;
				width: 100%;
				height: 100%;
				border-radius: inherit;
				margin: 0;
			}

			img {
				object-fit: cover;
				object-position: center;
			}
		}

		.content {
			.a-text {
				@include fluid-type(320px, $grid-container, 14px, 22px);
			}

			.a-btn {
				margin-top: $xs-value;
			}
		}
	}

	&_container {
		@include xy-grid;
		position: relative;
	}

	&_content {
		@include xy-cell(12);
		box-sizing: border-box;
		// padding-right: $xs-value + $grid-margin-gutters * 0.5;

		// @include breakpoint(small) {
		// 	padding-right: $s-value + $grid-margin-gutters * 0.5;
		// }

		// @include breakpoint(medium) {
		// 	padding-right: $xs-value + $grid-margin-gutters;
		// }

		@include breakpoint(large) {
			position: relative;
			z-index: 1;
			// padding-right: 0;
		}

		&.-titled {
			@include breakpoint(large) {
				@include xy-cell(9);
			}

			&.-big {
				@include breakpoint(large) {
					@include xy-cell(10);
				}
			}
		}

		&.-lined {
			@include breakpoint(large) {
				@include xy-cell(6);
				@include xy-cell-offset(3);
			}
		}

		&.-linedFull {
			@include breakpoint(large) {
				@include xy-cell(9);
				//@include xy-cell-offset(3);
			}
		}

		&.-slim {
			@include breakpoint(large) {
				@include xy-cell(10);
				@include xy-cell-offset(1);
			}
		}

		&.-left {
			@include breakpoint(large) {
				@include xy-cell(7);
				@include xy-cell-offset(1);
			}
		}

		&.-bot {
			margin-bottom: $l-value;

			@include breakpoint(large) {
				margin-bottom: $m-value;
			}
		}

		&.-slimBot {
			margin-bottom: $sm-value;

			@include breakpoint(large) {
				margin-bottom: $l-value;
			}
		}

		&.-padded {
			box-sizing: border-box;
			padding-right: $xs-value + $grid-margin-gutters * 0.5;

			@include breakpoint(small) {
				padding-right: $s-value + $grid-margin-gutters * 0.5;
			}

			@include breakpoint(medium) {
				padding-right: $xs-value + $grid-margin-gutters;
			}

			@include breakpoint(large) {
				padding-right: $m-value;
				padding-left: $m-value;
			}

			@include breakpoint(xxlarge) {
				padding-right: $l-value;
				padding-left: $l-value;
			}
		}
	}

	&_left {
		@include xy-cell(12);
		position: relative;
		order: 2;
		padding-top: $l-value;
		padding-bottom: $l-value;
		margin-top: $xl-value;

		@include breakpoint(large) {
			@include xy-cell(3);
			position: relative;
			order: initial;
			padding-top: 0;
			padding-bottom: 0;
			margin-top: 0;
		}

		&::before,
		&::after {
			position: absolute;
			top: 0;
			right: -$grid-margin-gutters * 0.5;
			left: -$grid-margin-gutters * 0.5;
			height: 1px;
			background: $primary;
			content: "";

			@include breakpoint(small) {
				right: -$xs-value - $grid-margin-gutters * 0.5;
				left: -$xs-value - $grid-margin-gutters * 0.5;
			}

			@include breakpoint(medium) {
				right: -$grid-margin-gutters;
				left: -$grid-margin-gutters;
			}

			@include breakpoint(large) {
				display: none;
			}
		}

		&::after {
			top: auto;
			bottom: 0;
		}
	}

	&_right {
		@include xy-cell(12);
		order: 1;
		margin-bottom: $m-value;

		@include breakpoint(large) {
			@include xy-cell(12);
			order: initial;
			margin-bottom: 0;
		}

		&.-noBot {
			margin-bottom: 0;
		}
	}

	&_half {
		@include xy-cell(12);

		@include breakpoint(large) {
			@include xy-cell(6);
		}

		&.-first {
			margin-bottom: $m-value;

			@include breakpoint(large) {
				margin-bottom: 0;
			}
		}

		&.-media {
			position: relative;

			img {
				@include breakpoint(large) {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
	}

	&_title {
		@include xy-cell(12);
		box-sizing: border-box;
		padding-right: $xs-value + $grid-margin-gutters * 0.5;
		margin-bottom: $sm-value;

		@include breakpoint(small) {
			padding-right: $s-value + $grid-margin-gutters * 0.5;
		}

		@include breakpoint(medium) {
			padding-right: $xs-value + $grid-margin-gutters;
		}

		@include breakpoint(large) {
			@include xy-cell(3);
			position: relative;
			z-index: 1;
			padding-right: 0;
			margin-bottom: 0;
		}

		&.-slim {
			@include breakpoint(large) {
				@include xy-cell(2);
			}
		}

		&.-bot {
			margin-bottom: $sm-value;

			@include breakpoint(large) {
				margin-bottom: $l-value;
			}
		}

		&.-center {
			@include breakpoint(large) {
				@include xy-cell(12);
				text-align: center;
			}
		}
	}

	&_titleLift {
		@include breakpoint(large) {
			box-sizing: border-box;
			padding-top: $sm-value;
		}
	}

	&_text {
		@include xy-cell(12);
		box-sizing: border-box;
		padding-right: $xs-value + $grid-margin-gutters * 0.5;

		@include breakpoint(small) {
			padding-right: $s-value + $grid-margin-gutters * 0.5;
		}

		@include breakpoint(medium) {
			padding-right: $xs-value + $grid-margin-gutters;
		}

		@include breakpoint(large) {
			@include xy-cell(6);
			padding-right: $l-value;
			padding-left: $l-value;
		}

		&.-full {
			@include breakpoint(large) {
				@include xy-cell(7);
				padding-right: 0;
			}
		}
	}

	&_placeholder {
		@include breakpoint(large) {
			@include xy-cell(3);
		}
	}

	&_liftContainer {
		@include xy-cell(12);
		margin-top: $m-value;

		@include breakpoint(large) {
			position: absolute !important;
			top: 0;
			right: -$grid-margin-gutters * 0.5;
			bottom: 0;
			overflow: hidden;
			width: calc(25% + #{$grid-margin-gutters * 0.5});
			margin-top: 0;
			background: $secondary;
		}

		@include breakpoint(xlarge) {
			right: -$grid-margin-gutters * 0.5 - 120px;
			width: calc(120px + 25% + #{$grid-margin-gutters * 0.5});
		}

		img {
			@include breakpoint(large) {
				position: absolute;
				top: 0;
				// z-index: 2;
				opacity: 0;
				pointer-events: none;
			}
		}

		canvas {
			display: block;
			width: 100%;

			@include breakpoint(large) {
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
			}
		}
	}

	&_lift {
		@include breakpoint(large) {
			width: 100%;
			height: 100%;
		}
	}

	&_buttonContainer {
		display: flex;
		justify-content: center;
		box-sizing: border-box;
		padding-right: $xs-value + $grid-margin-gutters * 0.5;

		@include breakpoint(small) {
			padding-right: $s-value + $grid-margin-gutters * 0.5;
		}

		@include breakpoint(medium) {
			padding-right: $xs-value + $grid-margin-gutters;
		}

		@include breakpoint(large) {
			position: relative;
			z-index: 1;
			justify-content: center;
			padding-right: 0;
		}

		&.-scope {
			@include breakpoint(large) {
				padding-top: $xxl-value;
				padding-bottom: $xxl-value;
			}

			@include breakpoint(xxlarge) {
				padding-top: $xxl-value + $m-value;
				padding-bottom: $xxl-value + $m-value;
			}
		}
	}

	&_button {
		@include fluid-type(320px, $grid-container, 50px, 150px);
		@include fallback(color, $primary);
		font-weight: 500;
		line-height: 1.1em;
		text-align: center;

		// @include breakpoint(large) {
		// padding-top: $s-value;
		// padding-bottom: calc(#{$s-value} + 0.15em); // Compensate line-height
		// transition: transform $fast-duration;
		// text-decoration: none;
		// }

		// &:hover,
		// &:focus {
		// 	// @include breakpoint(large) {
		// 	// 	transform: scale(1.1) !important;
		// 	// }
		// 	span {
		// 		@include breakpoint(large) {
		// 			background-size: 100% 0.1em;
		// 		}
		// 	}
		// }

		// span {
		// 	@include text-stroke(1, $secondary);
		// 	background: linear-gradient(to right, $primary, $primary);
		// 	background-repeat: no-repeat;
		// 	background-position: 100% 95%;
		// 	background-size: 100% 0;
		// 	transition: background-size $fast-duration;
		// }
	}

	&_scope {
		display: none;

		@include breakpoint(large) {
			position: absolute;
			top: 0.5px;
			right: -$grid-margin-gutters * 0.5;
			bottom: 0.5px;
			left: -$grid-margin-gutters * 0.5;
			display: block;
			overflow: hidden;
		}

		@include breakpoint(xlarge) {
			right: -$xxl-value - $grid-margin-gutters * 0.5;
			left: -$xxl-value - $grid-margin-gutters * 0.5;
		}

		article,
		picture {
			@include breakpoint(large) {
				height: 100%;
			}
		}

		img {
			@include breakpoint(large) {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}

	&_svg {
		background: $primary;

		svg {
			display: block;
			fill: $secondary;
		}
	}

	&_image {
		@include xy-cell(12);

		&.-left {
			margin-bottom: $grid-margin-gutters;

			@include breakpoint(large) {
				@include xy-cell(6);
				margin-bottom: 0;
			}
		}

		&.-right {
			@include breakpoint(large) {
				@include xy-cell(6);
			}
		}
	}

	&_parallax {
		display: none;
	}
}

.o-body.-home {
	.o-block.-blockquote:last-child {
		margin-bottom: 0;
	}
}

.o-body.-eco {
	.o-block_scope {
		display: none;
	}
}

.o-body.-landingPage {
	.o-block {
		margin-top: $xl-value;
		margin-bottom: $xl-value;

		@include breakpoint(large) {
			margin-top: $xxl-value;
			margin-bottom: $xxl-value;
		}
	}
}
