.m-agencies {
	width: 100%;

	&_rows {
		overflow: hidden;
	}

	&_row {
		@include xy-cell(12);
		position: relative;
		box-sizing: border-box;
		padding-top: $s-value;
		padding-right: $xs-value + $grid-margin-gutters * 0.5;
		padding-bottom: $sm-value + $s-value;

		@include breakpoint(large) {
			height: 190px;
			padding-top: 0;
			padding-right: 0;
			padding-bottom: 0;
		}

		&.-clone {
			color: $secondary;

			@include breakpoint(large) {
				width: 100%;
				padding-right: $grid-margin-gutters * 0.5;
				padding-left: $grid-margin-gutters * 0.5;
				margin-right: 0;
				margin-left: 0;
				background: $primary;
				transition: clip-path $duration $easeInOutCubic;
				clip-path: inset(50% 0% 50% 0%);
			}

			&.-hovered {
				@include breakpoint(large) {
					clip-path: inset(0% 0% 0% 0%);
				}

				.m-viewOffers_arrow svg {
					@include breakpoint(large) {
						fill: $primary;
					}
				}
			}

			.a-heading {
				color: $secondary;
			}

			.m-agencies_details {
				display: flex;
			}

			.m-agencies_detail::after {
				background: $secondary;
			}
		}
	}

	&_wrapper {
		@include breakpoint(large) {
			display: flex;
			align-items: center;
			height: 100%;
		}
	}

	&_title {
		position: relative;
		line-height: 1em;

		@include breakpoint(large) {
			padding-right: $m-value;
		}
	}

	&_details {
		@include fluid-type(320px, $grid-container, 13px, 26px);
		position: absolute;
		right: -$grid-margin-gutters * 0.5;
		bottom: $s-value;
		left: -$grid-margin-gutters * 0.5;
		display: none;
		width: 100%;
		font-weight: 500;
		line-height: 1.1em;
		text-transform: uppercase;
		white-space: nowrap;

		@include breakpoint(large) {
			bottom: 23px;
		}
	}

	&_detailsScroll {
		display: flex;
	}

	&_detail {
		position: relative;
		padding-right: 33px;

		@include breakpoint(large) {
			padding-right: 47px;
		}

		&::after {
			position: absolute;
			top: 50%;
			right: 10px;
			width: 13px;
			height: 1px;
			background: $primary;
			content: "";

			@include breakpoint(large) {
				right: 15px;
				width: 17px;
			}
		}
	}

	&_clones {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		overflow: hidden;
		background: $primary;
		clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
		pointer-events: none;

		@include breakpoint(large) {
			background: transparent;
			clip-path: none;
		}
	}
}
