.a-icon {
	display: inline-block;
	font-weight: normal;
	font-size: 0.57em;
	font-family: "fontello", sans-serif;
	font-style: normal;
	line-height: 1em;
	transform: translateY(-0.1em);

	&.-heart {
		// margin-left: -0.12em;

		&::before {
			content: "\e800";
		}
	}

	&.-big {
		font-size: 0.8em;
	}
}
