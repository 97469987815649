.m-newsletter {
	max-width: 400px;
	margin-top: $sm-value;

	@include breakpoint(large) {
		max-width: 500px;
		margin-top: $l-value;
	}

	form {
		@include breakpoint(large) {
			display: flex;
			flex-flow: row wrap;
		}
	}

	.captcha {
		order: 3;
		width: 100%;
		margin-top: 20px;
	}

	.form-item-email {
		@include breakpoint(large) {
			order: 1;
			width: calc(100% - 126px - $s-value); // 120 is submit width
		}
	}

	input[type="email"] {
		@include fluid-type(320px, $grid-container, 12px, 22px);
		box-sizing: border-box;
		width: 100%;
		padding: 12px;
		border: none;
		border: 1px solid $primary;
		font-weight: 500;
		font-family: $font-family;
		color: $primary;
		background: transparent;
		appearance: none;

		@include breakpoint(large) {
			padding: 18px;
		}

		&::placeholder {
			color: $primary;
			opacity: 0.3;
		}
	}

	.js-form-item-legal {
		@include fluid-type(320px, $grid-container, 10px, 15px);
		margin-top: $xs-value;
		font-weight: 500;
		line-height: 1.2em;

		@include breakpoint(large) {
			order: 4;
			width: 100%;
			margin-top: $sm-value;
		}

		a {
			color: $primary;
		}
	}

	.webform-button--submit-container {
		position: relative;
		display: inline-block;
		margin-top: $sm-value * 0.5;

		@include breakpoint(large) {
			align-self: center;
			order: 2;
			width: 126px;
			margin-top: 0;
			margin-left: $s-value;
		}

		svg {
			position: absolute;
			top: 50%;
			right: 12px;
			width: 0.7em;
			height: 0.7em;
			transform: translateY(-50%);
			fill: $secondary;
		}
	}

	input[type=submit] {
		@include fluid-type(320px, $grid-container, 12px, 18px);
		box-sizing: border-box;
		padding: $xs-value $s-value;
		padding-right: $s-value + $xs-value;
		border: none;
		border-radius: 3px;
		font-weight: 500;
		font-family: $font-family;
		color: $secondary;
		text-align: center;
		text-decoration: none;
		background: $primary;
		cursor: pointer;
		appearance: none;

		@include breakpoint(large) {
			width: 100%;
			padding: $sm-value * 0.5 0;
			padding-right: $s-value;
			border-radius: 5px;
		}
	}
}
