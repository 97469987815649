.o-main {
	position: relative;

	&_lines {
		position: absolute;
		top: 0;
		right: $grid-margin-gutters * 0.5;
		bottom: -$xl-value - $s-value;
		z-index: -1;
		pointer-events: none;

		@include breakpoint(small) {
			right: $xs-value + $grid-margin-gutters * 0.5;
		}

		@include breakpoint(medium) {
			right: $grid-margin-gutters;
		}

		@include breakpoint(large) {
			top: -$s-value;
			right: calc(25% + 0.9375rem);
			bottom: -$xxl-value - $xl-value;
			left: calc(25% + 0.9375rem);
			z-index: -1;
		}

		@include breakpoint(xlarge) {
			right: calc(25% + 0.9375rem + 60px);
			left: calc(25% + 0.9375rem + 60px);
		}

		@include breakpoint(xxxxlarge) {
			max-width: 930px;
			margin-right: auto;
			margin-left: auto;
		}
	}

	&_line {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: $s-value;

		@include breakpoint(large) {
			right: auto;
			transform: translateX(-50%);
		}

		&::before {
			position: absolute;
			top: 0;
			right: 0;
			left: 0.5px;
			height: 1px;
			background: $quaternary;
			content: "";

			@include breakpoint(large) {
				right: -0.5px;
				left: 0;
			}
		}

		&::after {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 50%;
			width: 1px;
			background: $quaternary;
			content: "";
		}

		&:last-child {
			display: none;

			@include breakpoint(large) {
				right: 0;
				left: auto;
				display: block;
				transform: translateX(50%);
			}

			&::before {
				@include breakpoint(large) {
					right: 0;
					left: -0.5px;
				}
			}

			&::after {
				@include breakpoint(large) {
					right: 50%;
					left: auto;
				}
			}
		}
	}
}

.o-body.-annexe {
	.o-main_lines {
		bottom: -$xl-value - $m-value;

		@include breakpoint(large) {
			bottom: -$xxl-value - $xl-value;
		}
	}
}

.o-body.-firstLoad {
	.o-main {
		opacity: 0;
	}
}
