.m-viewCustomers {
	display: flex;
	flex-flow: row wrap;

	@include breakpoint(large) {
		justify-content: center;
	}

	&_customer {
		@include fluid-type(320px, $grid-container, 20px, 57px);
		position: relative;
		padding-right: $sm-value;
		font-weight: 500;
		line-height: 1.4em;

		@include breakpoint(large) {
			padding-right: $l-value;
			line-height: 1.6em;
		}

		&::after {
			position: absolute;
			right: $sm-value * 0.5;
			transform: translateX(50%);
			opacity: 0.5;
			content: "+";

			@include breakpoint(large) {
				right: $l-value * 0.5;
			}
		}

		&:last-child {
			padding-right: 0;

			&::after {
				display: none;
			}
		}
	}
}
