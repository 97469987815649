.o-menu {
	position: fixed;
	z-index: 2;
	opacity: 0;
	transition: opacity 0s $long-duration;
	pointer-events: none;
	inset: 0;

	&::before {
		@include fallback(background-color, $primary);
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transform: translateY(-100%);
		opacity: 0;
		transition: opacity 0s $longer-duration, transform $long-duration $easeInOutCubic, background-color $duration;
		content: "";
		pointer-events: none;
	}

	&_container {
		@include xy-grid;
		position: relative;
		z-index: 1;
		align-items: flex-end;
		height: 100%;
		padding-bottom: $sm-value;

		@include breakpoint(large) {
			padding-bottom: $l-value;
		}
	}

	&_header {
		position: absolute;
		top: 0;
		left: $grid-margin-gutters * 0.5;
		display: flex;
		align-items: center;
		height: $header-height;

		@include breakpoint(small) {
			left: $xs-value + $grid-margin-gutters * 0.5;
		}

		@include breakpoint(medium) {
			left: $grid-margin-gutters;
			height: auto;
			padding-top: $sm-value;
		}

		@include breakpoint(large) {
			left: $grid-margin-gutters * 0.5;
			padding-top: $m-value;
		}
	}

	&_left {
		@include xy-cell(8);

		.m-navSocial {
			margin-top: $m-value;
		}
	}

	&_right {
		@include xy-cell(4);

		.a-switch {
			margin-bottom: $s-value;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&_logo {
		@include fluid-type(320px, $grid-container, 24px, 49.5px);
		@include fallback(color, $secondary);
		display: inline-block;
		font-weight: 500;
		line-height: 1.3em;
		letter-spacing: -0.02em;
		text-decoration: none;
		opacity: 0;
		transition: opacity $fast-duration 0.48s, color $duration;

		@include breakpoint(large) {
			line-height: 0.9em;
		}

		span {
			@include breakpoint(large) {
				display: block;
			}
		}
	}

	&_visuals {
		display: none;

		@include breakpoint(large) {
			position: absolute;
			top: 0;
			right: 180px;
			bottom: 0;
			left: calc(33.333% + $grid-margin-gutters);
			display: block;
			visibility: hidden;
		}

		article {
			img {
				@include breakpoint(large) {
					position: absolute;
					top: 15%;
					right: 0;
					width: 35%;
					transform: translateY(-50%);
					clip-path: inset(100% 0% 0% 0%);
				}
			}

			&:nth-child(2n + 2) {
				img {
					@include breakpoint(large) {
						position: absolute;
						top: 85%;
						right: auto;
						left: 0;
						width: 55%;
					}
				}
			}
		}
	}
}

.o-body.-menuOn {
	.o-menu {
		overflow: hidden;
		height: 100%;
		opacity: 1;
		transition: opacity 0s;
		pointer-events: auto;

		&::before {
			transform: translateY(0);
			opacity: 1;
			transition: opacity 0s, transform $long-duration $easeInOutCubic, background-color $duration;
			pointer-events: auto;
		}
	}

	.o-menu_logo {
		opacity: 1;
		transition: opacity $fast-duration 0.15s, color $duration;
	}

	.o-menu_visuals {
		@include breakpoint(large) {
			visibility: visible;
		}
	}
}

.o-body.-fastDisappear {
	.o-menu {
		opacity: 0;
		transition: opacity 0s;
		pointer-events: none;

		&::before {
			transform: translateY(-100%);
			opacity: 0;
			transition: opacity 0s, transform 0s;
		}
	}

	.o-menu_logo {
		opacity: 0;
		transition: opacity 0s;
	}
}

.o-body.-eco {
	.o-menu_visuals {
		@include breakpoint(large) {
			display: none;
		}
	}
}
