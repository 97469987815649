.menu-local-tasks ul li:first-child {
	display: none;
}

.menu-local-tasks ul li {
	margin-bottom: 10px;
}

.node-preview-form-select {
	position: fixed;
	right: 0;
	bottom: 0;
	z-index: 499;
	width: auto;
	padding: 10px;
}

.tabs {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 99;
	border-radius: 0 3px 0 0;
	color: $primary;
	background: $secondary;

	li {
		display: inline;
	}

	a {
		@include fontSize(12px);
		display: inline-block;
		padding: 8px 12px 6px;
		color: $primary;
		text-decoration: none;

		&:hover,
		&:focus,
		&.active {
			border-bottom: 2px solid $primary;
		}
	}
}

// Drupal remove element
.visually-hidden {
	display: none;
}

.element-invisible {
	position: absolute !important;
	overflow: hidden;
	height: 1px;
	clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
	clip: rect(1px, 1px, 1px, 1px);
}

.element-invisible.element-focusable:active,
.element-invisible.element-focusable:focus {
	position: static !important;
	overflow: visible;
	height: auto;
	clip: auto;
}

// Contentinfo notifications
.toastify {
	@include fontSize(14px);
	box-sizing: border-box;
	max-width: calc(100% - 30px);
	padding-right: 50px;

	@include breakpoint(large) {
		max-width: calc(75% - 30px);
	}

	.contentinfo {
		overflow: auto;
		max-height: 150px;
	}
}

.toast-close {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	box-sizing: border-box;
	width: 30px;
	color: transparent;
	background: rgba(#000, 0.15);
	opacity: 1;

	&::before,
	&::after {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 12px;
		height: 1px;
		background: #fff;
		transform: translate(-50%) rotate(45deg);
		content: "";
	}

	&::after {
		transform: translate(-50%) rotate(-45deg);
	}
}

.form-item-view-mode {
	display: none !important;
}

a#edit-backlink,
.menu-local-tasks ul a {
	display: none;

	@include breakpoint(large) {
		display: inline-block;
		padding: 10px 25px;
		border: 1px solid #fff;
		border-radius: 22px;
		font-weight: 600;
		color: #fff;
		text-decoration: none;
		background: $swpl;

		&:hover,
		&:focus {
			background: $swpl_dark;
		}
	}
}

#toolbar-administration {
	display: none;

	// @include breakpoint(large) {
	// 	display: block;
	// }
}

body.toolbar-tray-open.toolbar-vertical.toolbar-fixed {
	margin-left: 0 !important;

	@include breakpoint(large) {
		margin-left: 240px !important;
		margin-left: 15rem !important;
	}
}

.adminimal-admin-toolbar {
	nav.menu-local-tasks {
		position: fixed;
		bottom: 0;
		z-index: 499;
		box-sizing: border-box;
		width: 10%;
		padding: 10px;
	}
}
