.m-contact {
	$bg-opacity: 0.05;
	$border-opacity: 0.28;
	$spacing: 1.5 * $xs-value;
	$bg: #f1fbf8;
	$border-color: #b9e4d8;

	@include xy-grid;

	margin-right: $grid-margin-gutters * -0.5;
	margin-left: $grid-margin-gutters * -0.5;

	&.-white {
		padding: $s-value;
		color: $secondary;
		background-color: $real-white;

		@include breakpoint(large) {
			padding: $sm-value;
		}

		@include breakpoint(xlarge) {
			padding: $m-value;
		}

		@include forFields {
			@include fluid-type(320px, $grid-margin-gutters, 14px, 20px);
			position: relative;
			z-index: 0;
			box-sizing: border-box;
			min-width: 1px;
			max-width: 100%;
			padding: $xs-value;
			border: none;
			border-bottom: 1px solid $border-color;
			font: inherit;
			font-weight: $weight-medium;
			color: $secondary;
			line-height: 1.2;
			background: $bg;
			transition: $fast-duration $easeInOutCubic;
			transition-property: border-color, background-color;

			@include breakpoint(large) {
				//flex: 0 1 auto;
				flex: 1;
			}

			&:hover,
			&:focus {
				border-bottom-color: $secondary;
			}

			&::placeholder {
				color: $secondary;
				opacity: 0.3;
			}
		}

		*::selection {
			color: $real-white;
			background-color: $secondary;
		}
	}
	&.-whitebook {
		@include breakpoint(large) {
			// display: block;
			align-items: center;
			height: 100%;
			box-sizing: border-box;
		}

		& > div  {
			display: flex;
			flex-direction: column;
			width: 100%;
		}

		form {
			@include breakpoint(large) {
				margin-top: $m-value;
			}
		}

		.form-item {
			@include breakpoint(large) {
				width: 100%;
			}
		}

		@include forFields {
			border-bottom: 1px solid $secondary;
			font: inherit;
			font-weight: $weight-medium;
			color: $secondary;
			background: #f6f5ff;
			width: 100%;
		}

		.captcha {
			width: 100%;
		}

		input[type=submit] {
			color: #fff;
		}
	}

	&_left {
		@include xy-cell(12);
		box-sizing: border-box;
		padding-right: $xs-value + $grid-margin-gutters * 0.5;
		margin-bottom: $sm-value;

		@include breakpoint(small) {
			padding-right: $s-value + $grid-margin-gutters * 0.5;
		}

		@include breakpoint(medium) {
			padding-right: $xs-value + $grid-margin-gutters;
		}

		@include breakpoint(large) {
			@include xy-cell(5);
			padding-right: 0;
			margin-bottom: 0;
		}
	}

	&_right {
		@include xy-cell(12);
		box-sizing: border-box;
		padding-right: $xs-value + $grid-margin-gutters * 0.5;

		@include breakpoint(small) {
			padding-right: $s-value + $grid-margin-gutters * 0.5;
		}

		@include breakpoint(medium) {
			padding-right: $xs-value + $grid-margin-gutters;
		}

		@include breakpoint(large) {
			@include xy-cell(4);
			@include xy-cell-offset(3);
			padding-top: $s-value;
			padding-right: 0;
		}
	}

	&_item {
		@include fluid-type(320px, $grid-container, 20px, 56px);
		margin-bottom: $sm-value;
		font-weight: 500;

		@include breakpoint(large) {
			margin-bottom: $l-value;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&_link {
		@include fluid-type(1024px, $grid-container, 14px, 22px);
		position: relative;
		padding: 0;
		border: none;
		margin-top: 7px;
		font-weight: 500;
		font-family: $font-family;
		color: $primary;
		text-decoration: underline;
		background: transparent;
		cursor: pointer;
		appearance: none;

		@include breakpoint(large) {
			margin-top: 0;
		}

		svg {
			display: inline-block;
			width: 14px;
			height: 14px;
			margin-left: 7px;
			transform: translateY(2px);
			stroke: $primary;

			@include breakpoint(large) {
				width: 19px;
				height: 18px;
				margin-left: $xs-value;
			}
		}
	}

	&_tip {
		@include fluid-type(320px, $grid-container, 10px, 13px);
		position: absolute;
		right: 0;
		bottom: -$xs-value;
		padding: $xxs-value;
		border-radius: 2px;
		color: $secondary;
		background: $primary;
		transform: translateY(100%);
		opacity: 0;
		pointer-events: none;

		&::before {
			position: absolute;
			top: 0;
			right: 4px;
			width: 8px;
			height: 8px;
			background: $primary;
			transform: translateX(-50%) translateY(-50%) rotate(45deg);
			content: "";
		}
	}

	form {
		max-width: 100%;

		@include breakpoint(xlarge) {
			display: flex;
			flex-flow: row wrap;
		}
	}

	.webform-button--submit-container {
		position: relative;
		display: block;
		margin-top: $s-value;
		color: $real-white;

		@include breakpoint(large) {
			margin-top: $m-value;
		}

		svg {
			position: absolute;
			top: 50%;
			right: $s-value;
			width: 0.75em;
			height: 0.75em;
			transform: translateY(-50%);
			fill: currentColor;
		}
	}

	input[type=submit] {
		$delta: $m-value;
		$pad-x: $spacing; //$m-value;
		$pad-y: $spacing; //12px;
		$pad-x-large: $spacing; //$m-value;
		$pad-y-large: $spacing; //18px;

		@include fluid-type(320px, $grid-container, 14px, 16px);
		box-sizing: border-box;
		width: 100%;
		padding: $pad-y $pad-x;
		padding-right: $pad-x + $delta;
		border: none;
		border-radius: 3px;
		font-weight: $weight-medium;
		font-family: $font-family;
		color: $primary;
		text-align: center;
		text-decoration: none;
		background: $secondary;
		cursor: pointer;
		appearance: none;

		@include breakpoint(large) {
			padding: $pad-y-large $pad-x-large;
			padding-right: $pad-x-large + $delta;
			border-radius: 5px;
		}

		&:hover {
			@include breakpoint(large) {
				text-decoration: underline;
			}
		}
	}

	.form-item {
		margin-bottom: $m-value;

		@include breakpoint(large) {
			margin-bottom: $sm-value;
		}

		@include breakpoint(xlarge) {
			flex: 0 0 100%;
		}

		a {
			color: inherit;
		}
	}

	.field-prefix {
		@include fluid-type(320px, $grid-margin-gutters, 14px, 20px);

		margin-right: $s-value;
	}

	.field-suffix {
		margin-left: $xxs-value;
	}

	.form-item-fname,
	.form-item-societe {
		display: flex;
		flex: 0 auto;
		justify-content: flex-start;
		align-items: center;

		@include breakpoint(xlarge) {
			max-width: min(50%, 375px);
		}

		.field-prefix,
		.field-suffix {
			flex: 0 auto;
		}

		.form-text {
			flex: 1;
		}
	}

	.form-item-fname {
		@include breakpoint(xlarge) {
			margin-right: $xs-value;
		}
	}

	.form-item-societe {
		@include breakpoint(xlarge) {
			margin-left: $xs-value;
		}
	}

	.webform-type-checkboxes {
		.field-prefix {
			display: block;
			margin-bottom: $spacing;
		}

		.form-checkboxes {
			display: flex;
			flex-flow: row wrap;
			margin-bottom: 0;
			//margin-top: -$spacing;
			margin-left: -$spacing;

			& > .form-item {
				position: relative;
				flex: 0 auto;
				margin-top: $spacing;
				margin-bottom: 0;
				margin-left: $spacing;

				input {
					position: absolute;
					z-index: 1;
					width: 100%;
					height: 100%;
					cursor: pointer;
					opacity: 0;
					inset: 0;

					&:checked + label {
						border-bottom-color: $secondary;
						color: $real-white;
						background-color: $secondary;
					}
				}

				label {
					@include fluid-type(320px, $grid-margin-gutters, 14px, 20px);
					position: relative;
					z-index: 0;
					padding: $xs-value;
					border: none;
					border-bottom: 1px solid $border-color;
					font: inherit;
					font-weight: $weight-medium;
					color: $secondary;
					line-height: 1.2;
					background-color: $bg;
					transition: $fast-duration $easeInOutCubic;
					transition-property: color, border-color, background-color;
				}
			}
		}
	}

	.js-form-type-textarea {
		.field-prefix {
			display: block;
			margin-bottom: $spacing;
		}
	}

	.form-item-rgpd {
		margin-top: $sm-value;

		label {
			@include fluid-type(320px, $grid-container, 12px, 16px);
			display: inline;
			margin-bottom: 0;
		}
	}

	.js-form-type-checkbox {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;

		input[type="checkbox"],
		input[type="radio"] {
			position: relative;
			flex: 0 0 15px;
			width: 15px;
			height: 15px;
			border: solid 1px $secondary;
			outline: none;
			margin: 0 $spacing 0 0;
			color: $secondary;
			vertical-align: middle;
			cursor: pointer;
			appearance: none;

			&:checked {
				background: $secondary;

				&::before {
					display: block;
				}
			}

			&::before {
				@include fontSize(12px);
				position: absolute;
				top: 50%;
				left: 50%;
				display: none;
				color: $real-white;
				transform: translate(-50%, -50%);
				content: "✔\fe0e";
			}
		}
	}
}
