.o-top {
	margin-bottom: $xl-value + $s-value;

	@include breakpoint(medium) {
		margin-bottom: $xl-value + $m-value;
	}

	@include breakpoint(large) {
		margin-bottom: $xl-value * 2;
	}

	@include breakpoint(xxxlarge) {
		margin-bottom: $xxl-value * 2;
	}

	&.-home {
		.o-top_container {
			padding-top: $xl-value;

			@include breakpoint(large) {
				padding-top: $l-value;
			}

			@include breakpoint(xxxlarge) {
				padding-top: $xl-value;
			}
		}

		.o-top_title {
			display: none;

			@include breakpoint(large) {
				@include xy-cell(10);
				@include xy-cell-offset(1);
				display: block;
				margin-bottom: 0;
			}
		}

		.o-top_content {
			@include xy-cell(12);
			position: relative;
			padding-bottom: 0.5em;

			@include breakpoint(large) {
				position: absolute;
				bottom: 9.5%;
				left: 46%;
				width: 26%;
				padding-bottom: 0;
				margin: 0;
			}

			@include breakpoint(xlarge) {
				left: 47%;
				width: 24%;
			}

			@include breakpoint(xxxlarge) {
				left: 49%;
				width: 21%;
			}

			@include breakpoint(xxxxlarge) {
				bottom: 9.5%;
			}

			// &::after {
			// 	position: absolute;
			// 	top: 0;
			// 	right: 0;
			// 	bottom: 0;
			// 	width: $xs-value - 1px;
			// 	background: $secondary;
			// 	content: "";

			// 	@include breakpoint(large) {
			// 		display: none;
			// 	}
			// }

			.a-text {
				@include fluid-type(320px, $grid-container, 36px, 80px);
				line-height: 1.1em;

				@include breakpoint(large) {
					@include fluid-type(1024px, $grid-container, 16px, 24px);
					line-height: 1.3em;
				}
			}
		}
	}

	&.-landingPage {
		margin-bottom: $xl-value;

		@include breakpoint(large) {
			margin-bottom: $xxl-value;
		}

		.o-top_title {
			margin-bottom: $sm-value;

			@include breakpoint(large) {
				@include xy-cell(12);
				display: flex;
				justify-content: center;
			}
		}

		.o-top_content {
			@include breakpoint(large) {
				@include xy-cell(4);
				@include xy-cell-offset(5);
				padding-right: $sm-value;
				padding-left: $sm-value;
			}
		}
	}

	&.-annexe {
		margin-bottom: $xxl-value;

		@include breakpoint(large) {
			margin-bottom: $xxl-value + $xl-value;
		}

		.o-top_container {
			@include breakpoint(large) {
				padding-top: $l-value;
			}

			&::before,
			&::after {
				@include breakpoint(large) {
					position: absolute;
					top: $l-value;
					right: calc(25% + $grid-margin-gutters * 0.5);
					left: 0;
					height: 1px;
					background: $quaternary;
					content: "";
				}

				@include breakpoint(xlarge) {
					left: -$xxl-value;
				}
			}

			&::after {
				@include breakpoint(large) {
					top: auto;
					bottom: 0;
				}
			}
		}

		.o-top_title {
			@include breakpoint(large) {
				position: absolute;
				top: $l-value;
				right: calc(25% + #{$grid-margin-gutters * 0.5});
				bottom: 0;
				left: 0;
				display: flex;
				align-items: center;
				width: auto;
				padding-left: $grid-margin-gutters * 0.5;
				margin: 0;
				color: $secondary;
				background: $primary;
				clip-path: circle(0 at 50% 50%);
			}

			@include breakpoint(xlarge) {
				left: -$xxl-value;
				padding-left: $grid-margin-gutters * 0.5 + $xxl-value;
			}

			.a-heading {
				@include breakpoint(large) {
					color: $secondary;
				}
			}
		}
	}

	&.-customerCase {
		margin-bottom: $xl-value + $m-value;

		@include breakpoint(large) {
			margin-bottom: $xxl-value + $xl-value;
		}
	}

	&.-sitemap {
		.o-top_container {
			padding-bottom: $m-value;

			@include breakpoint(large) {
				padding-bottom: $xxl-value;
			}
		}
	}

	&.-slim {
		margin-bottom: -$l-value;

		@include breakpoint(large) {
			margin-bottom: -100px;
		}
	}

	&_container {
		@include xy-grid;
		position: relative;
		align-items: center;
		padding-top: $l-value;

		@include breakpoint(large) {
			padding-top: $xl-value + $s-value;
		}

		@include breakpoint(xlarge) {
			padding-top: $xxl-value + $s-value;
		}
	}

	&_breadcrumb {
		display: none;

		@include breakpoint(large) {
			position: absolute;
			top: $xs-value;
			left: $grid-margin-gutters * 0.5;
			z-index: 1;
			display: block;
			transition: opacity $duration;
		}

		li {
			@include fluid-type(1024px, $grid-container, 13px, 15.5px);
			position: relative;
			display: inline-block;
			padding-left: $s-value;
			margin-left: 2px;
			font-weight: 500;

			&::before {
				position: absolute;
				top: 50%;
				left: 0;
				width: 13px;
				height: 1px;
				background: $primary;
				content: "";
			}

			&:first-child {
				padding-left: 0;
				margin-left: 0;

				&::before {
					display: none;
				}
			}
		}

		a {
			color: $primary;
			text-decoration: none;

			&:hover,
			&:focus {
				@include breakpoint(xlarge) {
					text-decoration: underline;
				}
			}
		}
	}

	&_title {
		@include xy-cell(12);
		box-sizing: border-box;
		padding-right: $xs-value + $grid-margin-gutters * 0.5;
		margin-bottom: $m-value;

		@include breakpoint(small) {
			padding-right: $s-value + $grid-margin-gutters * 0.5;
		}

		@include breakpoint(medium) {
			padding-right: $xs-value + $grid-margin-gutters;
		}

		@include breakpoint(large) {
			@include xy-cell(9);
			// @include xy-cell-offset(1);
			// position: relative;
			// z-index: 2; // Removed to have image under lines
			padding-right: 0;
			margin-bottom: $l-value;
			letter-spacing: -0.02em;
			pointer-events: none;
		}

		&.-noBot {
			margin-bottom: 0;
		}
	}

	&_svg {
		@include xy-cell(12);
		position: relative;
		box-sizing: border-box;
		overflow: hidden;
		padding-bottom: 16%;
		margin-top: -$xs-value;
		margin-bottom: $sm-value;

		@include breakpoint(large) {
			@include xy-cell(12);
			display: flex;
			justify-content: center;
			margin-bottom: $m-value;
			pointer-events: none;
		}

		&.-animated {
			svg {
				animation: sprite 2s steps(4) infinite;
			}
		}

		svg {
			position: absolute;
			top: 0;
			left: 0;
			padding-right: $xs-value + $grid-margin-gutters * 0.5;
			fill: $primary;

			@include breakpoint(small) {
				padding-right: $s-value + $grid-margin-gutters * 0.5;
			}

			@include breakpoint(medium) {
				padding-right: $xs-value + $grid-margin-gutters;
			}

			@include breakpoint(large) {
				padding-right: 0;
			}
		}
	}

	&_phone {
		@include fluid-type(320px, $grid-container, 30px, 118px);
		font-weight: 500;
		color: $primary;
		text-decoration: none;
	}

	&_visual {
		@include xy-cell(12);
		margin-bottom: $m-value;

		@include breakpoint(large) {
			@include xy-cell(4);
			@include xy-cell-offset(4);
			margin-bottom: 0;
		}

		&.-noBot {
			margin-bottom: 0;
		}

		picture {
			@include breakpoint(large) {
				margin-bottom: -$xxl-value * 2;
			}
		}
	}

	&_whitebook {
		@include xy-cell(12);
		margin-top: $m-value;

		@include breakpoint(large) {
			@include xy-cell(10);
			@include xy-cell-offset(1);
			margin-top: $xxl-value;
		}
	}

	&_content {
		@include xy-cell(10);
		box-sizing: border-box;
		padding-right: $xs-value + $grid-margin-gutters * 0.5;

		@include breakpoint(small) {
			padding-right: $s-value + $grid-margin-gutters * 0.5;
		}

		@include breakpoint(medium) {
			padding-right: $xs-value + $grid-margin-gutters;
		}

		@include breakpoint(large) {
			@include xy-cell(3);
			@include xy-cell-offset(9);
			padding-right: 0;
		}
	}

	&_letters {
		@include xy-cell(12);
		display: flex;
		justify-content: space-evenly;
		box-sizing: border-box;
		padding-right: $xs-value + $grid-margin-gutters * 0.5;

		@include breakpoint(small) {
			padding-right: $s-value + $grid-margin-gutters * 0.5;
		}

		@include breakpoint(medium) {
			padding-right: $xs-value + $grid-margin-gutters;
		}

		@include breakpoint(large) {
			@include xy-cell(6);
			@include xy-cell-offset(6);
			flex-direction: column;
			padding-right: 0;
		}
	}

	&_letter {
		@include fluid-type(320px, $grid-container, 150px, 400px);
		font-weight: 500;
		line-height: 0.9em;

		@include breakpoint(large) {
			line-height: 0.75em;
		}

		&:first-child {
			transform: translateY(-$sm-value);

			@include breakpoint(large) {
				transform: translateY(25%);
			}
		}

		&:nth-child(2) {
			transform: translateY($xl-value);

			@include breakpoint(large) {
				align-self: flex-end;
				transform: none;
			}
		}

		&:last-child {
			@include breakpoint(large) {
				transform: translateX(8%) translateY(-25%);
			}
		}
	}

	&_menu {
		display: none;

		@include breakpoint(large) {
			position: absolute;
			top: $m-value;
			right: $grid-margin-gutters * 0.5 + $l-value;
			display: block;
		}

		.m-navMain_item {
			@include breakpoint(large) {
				transform: none;
				opacity: 1;
			}
		}

		.m-navMain_link {
			@include breakpoint(large) {
				@include fluid-type(320px, $grid-container, 14px, 20px);
				padding: 8px 0;
				font-weight: 400;
				color: $primary;
			}
		}

		.m-navMain_arrow {
			@include breakpoint(large) {
				display: none;
			}
		}
	}

	&_banner {
		position: relative;
		z-index: 1;
	}
}

.o-body.-loading,
.o-body.-firstLoad {
	.o-top_breadcrumb {
		@include breakpoint(large) {
			opacity: 0;
		}
	}
}

@keyframes sprite {
	from {
		transform: translateY(0);
	}

	to {
		transform: translateY(-80%);
	}
}
