.m-survey {
	&.-loading {
		.m-survey_answers {
			opacity: 0.5;
			pointer-events: none;
		}
	}

	& > .a-text.-fat {
		margin-bottom: $xxs-value;
	}

	&_answers {
		display: inline-block;
		flex-direction: column;
		align-items: flex-start;
		margin-top: $sm-value;
		transition: opacity $faster-duration;

		@include breakpoint(large) {
			margin-top: $l-value;
		}
	}

	&_answer {
		@include fluid-type(320px, $grid-container, 14px, 26px);
		position: relative;
		display: inline-block;
		box-sizing: border-box;
		width: 70%;
		min-width: 100%;
		max-width: 100%;
		//padding: 12px 18px;
		// padding-left: $m-value + $xs-value;
		border: 1px solid $tertiary;
		border-radius: 40px;
		margin-bottom: 12px;
		font-weight: 500;
		color: $tertiary;

		@include breakpoint(large) {
			//padding: 18px 22px;
			// padding-left: $l-value + $s-value;
			min-width: auto;
			margin-bottom: $s-value;
			cursor: pointer;
			transition: background-color $faster-duration, color $faster-duration;
		}

		&:hover {
			@include breakpoint(large) {
				color: $secondary;
				background: $tertiary;
			}
		}

		&.-current {
			color: $secondary;
			background: $tertiary;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&.-answered {
			width: 100%;
			padding-right: $l-value + $xs-value;
			pointer-events: none;

			// @include breakpoint(large) {
			// 	padding-right: 22px + $l-value;
			// }
		}
	}

	&_title {
		@include fluid-type(320px, $grid-container, 14px, 22px);
		padding: 12px 18px;
		line-height: 1.2em;

		@include breakpoint(large) {
			padding: 18px 22px;
		}
	}

	&_percent {
		@include fluid-type(320px, $grid-container, 12px, 18px);
		position: absolute;
		top: 50%;
		right: 18px;
		font-weight: 600;
		transform: translateY(-50%);
	}
}
