.a-text {
	font-family: $font-family-alt;
	line-height: 1.6em;

	&.-white {
		color: #fff;
	}

	&.-primary {
		color: $primary;
	}

	&.-secondary {
		color: $secondary;
	}

	&.-tertiary {
		color: $tertiary;
	}

	&.-fatter {
		@include fluid-type(320px, $grid-container, 16px, 40px);
		font-weight: 500;
		font-family: $font-family;
		line-height: 1.3em;
	}

	&.-fat {
		@include fluid-type(320px, $grid-container, 16px, 27.5px);
		font-weight: 500;
		font-family: $font-family;
		line-height: 1.3em;
	}

	&.-notThatFat {
		@include fluid-type(320px, $grid-container, 14px, 22.5px);
		font-weight: 500;
		font-family: $font-family;
		line-height: 1.2em;
	}

	&.-slim {
		@include fluid-type(320px, $grid-container, 12px, 17px);
		line-height: 1.5em;
	}

	&.-slimer {
		@include fluid-type(320px, $grid-container, 11px, 16px);
		font-weight: 500;
		line-height: 1.45em;
	}

	&.-blog {
		@include breakpoint(large) {
			padding-top: $sm-value;
		}

		div[id] {
			margin-top: $xl-value;
			transform: translateY(-$sm-value);

			@include breakpoint(large) {
				margin-top: $xxl-value;
			}
		}
	}

	&.-uppercase {
		font-weight: 500;
		text-transform: uppercase;
	}

	&.-centered {
		text-align: center;
	}

	&.-customer {
		span {
			display: block;
		}
	}

	.text-align-center {
		text-align: center;
	}

	.text-align-right {
		text-align: right;
	}

	.a-heading.-h2,
	h2 {
		@include fontSize(24px);
		margin-top: $m-value;
		margin-bottom: $grid-margin-gutters;
		font-weight: 500;
		font-family: $font-family;
		color: $primary;
		line-height: 1.3em;

		@include breakpoint(large) {
			@include fontSize(32px);
			margin-top: $l-value;
			margin-bottom: $m-value;
		}
	}

	.a-heading.-h3,
	h3 {
		@include fontSize(18px);
		margin-top: $grid-margin-gutters;
		margin-bottom: $s-value;
		font-weight: 500;
		font-family: $font-family;
		color: $primary;
		line-height: 1.3em;

		@include breakpoint(large) {
			@include fontSize(24px);
			margin-top: $m-value;
		}
	}

	.a-heading.-h4,
	h4 {
		@include fontSize(16px);
		margin-top: $s-value;
		margin-bottom: $xs-value;
		font-weight: 500;
		font-family: $font-family;
		color: $primary;
		line-height: 1.3em;

		@include breakpoint(large) {
			@include fontSize(20px);
		}
	}

	p {
		margin-bottom: $s-value;
	}

	ul,
	ol {
		$margin: 1.25em;
		margin-bottom: $margin;

		margin-left: $margin;
	}

	ul {
		list-style-type: square;
	}

	ol {
		list-style-type: decimal;
	}

	li {
		margin-bottom: $xxs-value;
	}

	a:not(.a-btn) {
		color: $primary;
	}

	blockquote {
		position: relative;
		padding-top: $sm-value;
		padding-bottom: $sm-value;
		margin: 0 auto;
		margin-top: $xl-value;
		margin-bottom: $xl-value;
		color: $primary;
		text-align: center;

		@include breakpoint(large) {
			padding-top: $m-value;
			padding-bottom: $m-value;
			margin-top: $xxl-value;
			margin-bottom: $xxl-value;
		}

		&::before,
		&::after {
			position: absolute;
			top: 0;
			left: 50%;
			width: $m-value;
			height: 1px;
			background: $quaternary;
			transform: translateX(-50%);
			content: "";

			@include breakpoint(large) {
				width: $xxl-value * 2;
			}
		}

		&::after {
			top: auto;
			bottom: 0;
		}

		p {
			@include fluid-type(320px, $grid-container, 20px, 28px);
			font-weight: 500;
			font-family: $font-family;
			line-height: 1.5em;
		}

		& > *:last-child {
			margin-bottom: 0;
		}
	}

	article {
		margin-bottom: $s-value;

		@include breakpoint(large) {
			margin-bottom: $sm-value;
		}

		img {
			max-width: min(100%, 800px);
		}
	}

	figure {
		margin-bottom: $s-value;

		img {
			margin-bottom: 0;
		}
	}

	figcaption {
		@include fontSize(14px);
	}

	.a-heading + blockquote {
		margin-top: 0;
	}

	.align-left {
		text-align: left;
	}

	.align-right {
		text-align: right;
	}

	.align-center {
		text-align: center;
	}

	& > *:first-child {
		margin-top: 0 !important;
	}

	& > *:last-child {
		margin-bottom: 0 !important;
	}

	&_sub {
		@include fluid-type(320px, $grid-container, 11px, 16px);
		display: block;
		color: $quaternary;
		line-height: 1.2em;
	}

	strong {
		font-weight: 600;
	}
}
