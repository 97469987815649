@charset 'UTF-8';

@import "~foundation-sites/scss/foundation";
@import "~swiper/css";
@import "~toastify-js/src/toastify.css";
@import "~locomotive-scroll/dist/locomotive-scroll";

// Config
@import "0_utilities/mixins";
@import "0_utilities/reset";
@import "0_utilities/variables";
@import "0_utilities/config";
@import "0_utilities/base";
@import "0_utilities/drupal";
@import "0_utilities/ckeditor";

// Atoms
@import "1_atoms/btn";
@import "1_atoms/burger";
@import "1_atoms/customerButton";
@import "1_atoms/heading";
@import "1_atoms/icon";
@import "1_atoms/image";
@import "1_atoms/link";
@import "1_atoms/loader";
@import "1_atoms/menuToggle";
@import "1_atoms/pill";
@import "1_atoms/share";
@import "1_atoms/switch";
@import "1_atoms/teaser";
@import "1_atoms/text";
@import "1_atoms/video";
@import "1_atoms/wrapper";

// Molecules
@import "2_molecules/agencies";
@import "2_molecules/blockquote";
@import "2_molecules/cards";
@import "2_molecules/contact";
@import "2_molecules/folders";
@import "2_molecules/form";
@import "2_molecules/gallery";
@import "2_molecules/keyFigures";
@import "2_molecules/listCustomerCases";
@import "2_molecules/listExpertises";
@import "2_molecules/logos";
@import "2_molecules/navFooter";
@import "2_molecules/navLegal";
@import "2_molecules/navMain";
@import "2_molecules/navOffers";
@import "2_molecules/navSocial";
@import "2_molecules/newsletter";
@import "2_molecules/promoted";
@import "2_molecules/slider";
@import "2_molecules/summary";
@import "2_molecules/survey";
@import "2_molecules/textsImage";
@import "2_molecules/trends";
@import "2_molecules/viewArticle";
@import "2_molecules/viewCustomers";
@import "2_molecules/viewCustomercases";
@import "2_molecules/viewJobs";
@import "2_molecules/viewOffers";
@import "2_molecules/viewRessource";
@import "2_molecules/viewTeam";
@import "2_molecules/viewSubsidiaries";
@import "2_molecules/worths";

// Organisms
@import "3_organisms/articleTop";
@import "3_organisms/block";
@import "3_organisms/body";
@import "3_organisms/footer";
@import "3_organisms/header";
@import "3_organisms/main";
@import "3_organisms/menu";
@import "3_organisms/top";

// Important
@import "1_atoms/imageLoader";
@import "1_atoms/videoLoader";

// Plugins
@import "99_plugins/advancedPointer";
@import "99_plugins/asscroll";
@import "99_plugins/butter";

@font-face {
	font-weight: normal;
	font-family: "fontello";
	font-style: normal;
	src:
		url("../../fonts/fontello.woff2?80410871") format("woff2"),
		url("../../fonts/fontello.woff?80410871") format("woff");
}

@font-face {
	font-weight: 500;
	font-family: "TT Norms";
	font-style: normal;
	src:
		url("../../fonts/TTNorms-Medium.woff2") format("woff2"),
		url("../../fonts/TTNorms-Medium.woff") format("woff");
}

@font-face {
	font-weight: bold;
	font-family: "TT Norms";
	font-style: normal;
	src:
		url("../../fonts/TTNorms-Bold.woff2") format("woff2"),
		url("../../fonts/TTNorms-Bold.woff") format("woff");
}

@font-face {
	font-weight: normal;
	font-family: "TT Norms";
	font-style: normal;
	src:
		url("../../fonts/TTNorms-Regular.woff2") format("woff2"),
		url("../../fonts/TTNorms-Regular.woff") format("woff");
}

@font-face {
	font-weight: bold;
	font-family: "Poppins";
	font-style: normal;
	src:
		url("../../fonts/Poppins-SemiBold.woff2") format("woff2"),
		url("../../fonts/Poppins-SemiBold.woff") format("woff");
}

@font-face {
	font-weight: normal;
	font-family: "Poppins";
	font-style: normal;
	src:
		url("../../fonts/Poppins-Regular.woff2") format("woff2"),
		url("../../fonts/Poppins-Regular.woff") format("woff");
}

// Libs
@include foundation-xy-grid-classes;
