.a-video {
	&_container {
		position: relative;
		z-index: 1;
		padding-bottom: 56.25%;
		margin: auto;

		&::before {
			content: "";
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
		}
	}

	iframe,
	video {
		position: absolute;
		top: 0;
		left: 0;
		box-sizing: border-box;
		width: 100%;
		height: 100%;
	}

	video {
		border: 1px solid $primary;
		transition: border-color $faster-duration;

		&.loaded {
			border-color: transparent;
		}
	}
}
