.o-articleTop {
	&_container {
		@include xy-grid;
		position: relative;
		align-items: flex-start;
		padding-top: $l-value;

		@include breakpoint(large) {
			// align-items: flex-s;
			padding-top: $xl-value;
		}

		@include breakpoint(xlarge) {
			padding-top: $xxl-value;
		}
	}

	&_left {
		@include xy-cell(12);
		box-sizing: border-box;
		margin-bottom: $m-value;

		@include breakpoint(large) {
			@include xy-cell(7);
			@include xy-cell-offset(1);
			position: relative;
			margin-bottom: 0;
			// padding-bottom: 180px;
		}
	}

	&_right {
		@include xy-cell(12);
		box-sizing: border-box;
		padding-right: $xs-value + $grid-margin-gutters * 0.5;
		padding-bottom: $l-value;

		@include breakpoint(small) {
			padding-right: $s-value + $grid-margin-gutters * 0.5;
		}

		@include breakpoint(medium) {
			padding-right: $xs-value + $grid-margin-gutters;
		}

		@include breakpoint(large) {
			@include xy-cell(3);
			@include xy-cell-offset(1);
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding-right: 0;
			padding-bottom: 0;
			margin-top: 180px;
		}
	}

	&_title {
		padding-right: $xs-value + $grid-margin-gutters * 0.5;
		margin-bottom: $m-value;

		@include breakpoint(small) {
			padding-right: $s-value + $grid-margin-gutters * 0.5;
		}

		@include breakpoint(medium) {
			padding-right: $xs-value + $grid-margin-gutters;
		}

		@include breakpoint(large) {
			// position: relative;
			// z-index: 1;
			padding-right: 0;
			margin-bottom: $xxl-value;
			pointer-events: none;
		}
	}

	&_visual {
		margin-right: -$grid-margin-gutters * 0.5;
		margin-left: -$grid-margin-gutters * 0.5;

		@include breakpoint(large) {
			position: relative;
			z-index: 1;
			align-items: stretch;
			margin-right: 0;
			margin-left: 0;
		}
	}

	&_legend {
		padding-right: $xs-value + $grid-margin-gutters * 0.5;
		margin-top: $xs-value;

		@include breakpoint(small) {
			padding-right: $s-value + $grid-margin-gutters * 0.5;
		}

		@include breakpoint(medium) {
			padding-right: $xs-value + $grid-margin-gutters;
		}

		@include breakpoint(large) {
			padding-right: 0;
			margin-top: $s-value;
		}
	}

	&_audio {
		margin-bottom: $sm-value;

		audio {
			width: 100%;
		}
	}

	&_date {
		display: inline-block;
		font-weight: 500;

		&.a-text.-slim {
			font-family: $font-family;
		}
	}

	&_time {
		display: block;
		font-weight: 500;

		@include breakpoint(large) {
			margin-top: 3px;
		}

		&.a-text.-slim {
			font-family: $font-family;
		}
	}

	&_author {
		margin-top: $xs-value;

		@include breakpoint(large) {
			margin-top: $l-value;
		}

		.a-text {
			font-family: $font-family;
		}
	}
}
