.m-folders {
	display: none;

	@include breakpoint(large) {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-top: $xl-value;
		font-weight: 500;
	}

	&:first-child {
		@include breakpoint(large) {
			margin-top: $xxs-value;
		}
	}

	.a-text {
		@include breakpoint(large) {
			margin-bottom: $xs-value;
		}
	}

	&_folder {
		@include breakpoint(large) {
			margin-bottom: $s-value;
			color: $primary;
		}

		&:last-child {
			@include breakpoint(large) {
				margin-bottom: 0;
			}
		}
	}
}
