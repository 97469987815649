.o-body {
	text-size-adjust: 100%;
	scroll-behavior: smooth;
	transition: background-color $faster-duration;

	@include breakpoint(large) {
		scroll-behavior: auto;
	}

	// &.-menuOn {
	// 	overflow: hidden !important;
	// }

	&.-loading {
		cursor: wait;

		.o-body_loader {
			opacity: 1;
		}
	}

	&_loader {
		position: fixed;
		right: $grid-margin-gutters * 0.5;
		bottom: $grid-margin-gutters * 0.5;
		z-index: 99;
		box-sizing: border-box;
		width: 20px;
		height: 20px;
		border: 1px solid $primary;
		border-bottom-color: transparent;
		border-radius: 50%;
		opacity: 0;
		transition: opacity $faster-duration, background-color $faster-duration;
		animation: rotation 0.75s linear infinite;

		@include breakpoint(large) {
			right: $sm-value;
			bottom: $sm-value;
			width: 30px;
			height: 30px;
		}
	}
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
