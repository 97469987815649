.a-pill {
	display: inline-block;
	border-radius: $xxl-value;
	//font-weight: 500;
	color: $secondary;
	text-align: center;
	background: $primary;
	padding: $xxs-value $s-value 0;
	margin: $xxs-value;
}
