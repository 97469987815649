.a-link {
	position: relative;
	display: inline-block;
	padding-right: $sm-value;
	padding-bottom: 8px;
	outline: none;
	margin-top: $m-value;
	font-weight: 500;
	color: $primary;
	text-transform: uppercase;
	text-decoration: none;

	@include breakpoint(large) {
		margin-top: $l-value;
	}

	&::before {
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		height: 1px;
		background: $primary;
		content: "";
	}

	&::after {
		@include breakpoint(xlarge) {
			position: absolute;
			top: -0.95em;
			right: -1.05em;
			width: 1.75em;
			height: 1.75em;
			border-radius: 50%;
			background: $primary;
			transform: scale(0);
			transition: transform $faster-duration;
			content: "";
		}
	}

	&_arrow {
		position: absolute;
		top: 0;
		right: 0;
		width: 22px;
		height: 22px;
		transform: translate(50%, -50%);
		pointer-events: none;

		&::before {
			@include breakpoint(xlarge) {
				position: absolute;
				border-radius: 50%;
				background: $primary;
				transform: scale(0);
				transition: transform $faster-duration;
				content: "";
				inset: 0;
			}
		}

		svg {
			@include fallback(fill, $primary);
			position: absolute;
			top: 6px;
			left: 6px;
			display: block;
			width: 8px;
			height: 8px;
			transform: translate(-75%, 75%);
			transition: fill $duration, transform $duration;

			@include breakpoint(xlarge) {
				width: 10px;
				height: 10px;
			}
		}
	}

	&:hover,
	&:focus {
		.a-link_arrow {
			&::before {
				@include breakpoint(xlarge) {
					transform: scale(1);
					transition: transform $duration $easeOutBack;
				}
			}

			svg {
				@include breakpoint(xlarge) {
					@include fallback(fill, $secondary);
					transform: translate(0, 0);
				}
			}
		}
	}
}
