.m-trends {
	padding-right: $xs-value + $grid-margin-gutters * 0.5;
	counter-reset: css-counter 0;

	@include breakpoint(small) {
		padding-right: $s-value + $grid-margin-gutters * 0.5;
	}

	@include breakpoint(medium) {
		padding-right: $xs-value + $grid-margin-gutters;
	}

	@include breakpoint(large) {
		position: absolute;
		top: $xl-value;
		left: 0;
		padding-right: 0;
	}

	&_title {
		margin-bottom: $sm-value;

		@include breakpoint(large) {
			margin-bottom: $l-value;
		}
	}

	&_trend {
		position: relative;
		box-sizing: border-box;
		min-height: $sm-value;
		padding-left: $m-value;
		margin-bottom: $sm-value;
		counter-increment: css-counter 1;

		@include breakpoint(large) {
			padding-left: $l-value;
		}

		&::before {
			@include fluid-type(320px, $grid-container, 34px, 60px);
			position: absolute;
			top: 0;
			left: 0;
			font-weight: 500;
			content: counter(css-counter);
		}

		&:last-child {
			margin-bottom: 0;
		}

		&:hover {
			.a-text {
				@include breakpoint(large) {
					text-decoration: underline;
				}
			}
		}

		.a-text {
			min-height: 2.3em;
		}
	}

	&_link {
		position: absolute;
		inset: 0;
	}
}
