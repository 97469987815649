.m-keyFigures {
	@include xy-grid;
	box-sizing: border-box;
	padding-right: $xs-value + $grid-margin-gutters * 0.5;
	margin-right: -$grid-margin-gutters * 0.5;
	margin-bottom: -$sm-value;
	margin-left: -$grid-margin-gutters * 0.5;

	@include breakpoint(small) {
		padding-right: $s-value + $grid-margin-gutters * 0.5;
	}

	@include breakpoint(medium) {
		padding-right: $xs-value + $grid-margin-gutters;
	}

	@include breakpoint(large) {
		padding-right: 0;
		margin-bottom: -$l-value;
	}

	&_item {
		@include xy-cell(6);
		margin-bottom: $sm-value;
		font-weight: 500;

		@include breakpoint(large) {
			@include xy-cell(3);
			margin-bottom: $l-value;
		}

		&:first-child {
			@include breakpoint(large) {
				@include xy-cell-offset(4);
			}
		}

		&:nth-child(2) {
			@include breakpoint(large) {
				@include xy-cell-offset(2);
				transform: translateY($xl-value);
			}
		}

		&:nth-child(3) {
			@include breakpoint(large) {
				@include xy-cell-offset(6);
			}
		}
	}

	&_number {
		@include fluid-type(320px, $grid-container, 30px, 90px);
		margin-bottom: $xs-value;
		color: $tertiary;
		letter-spacing: -0.1em;
	}
}
