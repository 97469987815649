$cursor-size: 96px;

.a-imageLoader {
	position: relative;
	z-index: 1;
	pointer-events: auto;

	@include breakpoint(large) {
		cursor: pointer;
	}

	&:hover {
		.a-imageLoader_trigger {
			&::before {
				@include breakpoint(large) {
					transform: scale(1);
					transition: transform 0.5s $easeOutBack;
				}
			}
		}

		.a-imageLoader_label {
			@include breakpoint(large) {
				opacity: 1;
				transition: opacity 0.3s 0.2s;
			}
		}
	}

	&.-loaded {
		pointer-events: none;

		.a-imageLoader_trigger {
			&::before {
				transform: scale(0);
				opacity: 0;

				@include breakpoint(large) {
					transition: transform 0.3s, opacity 0s 0.3s;
				}
			}
		}

		.a-imageLoader_label {
			opacity: 0;

			@include breakpoint(large) {
				transition: opacity 0.2s;
			}
		}
	}

	&_trigger {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 1;
		box-sizing: border-box;
		width: $cursor-size;
		height: $cursor-size;
		padding: $s-value;
		border: none;
		background: transparent;
		transform: translate(-50%, -50%);

		@include breakpoint(large) {
			pointer-events: none;
		}

		&::before {
			position: absolute;
			z-index: -1;
			border-radius: 50%;
			background: $primary;
			transition: transform 0.3s, opacity 0s 0.3s;
			content: "";
			inset: 0;

			@include breakpoint(large) {
				transform: scale(0);
				transition: transform 0.3s;
			}
		}
	}

	&_label {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 80%;
		font-weight: 700;
		font-size: 14px;
		font-family: $font-family;
		color: $secondary;
		line-height: 1.15;
		text-align: center;
		text-decoration: underline;
		transform: translate(-50%, -50%) rotate(-20deg);
		transition: opacity 0.2s;

		@include breakpoint(large) {
			opacity: 0;
		}
	}
}
