.has-scroll-smooth {
	scroll-behavior: initial;

	&.has-scroll-scrolling {
		iframe,
		[data-lenis-prevent] {
			pointer-events: none;
		}
	}

	[data-lenis-prevent] {
		overscroll-behavior: contain;
	}
}
