.a-menuToggle {
	position: absolute;
	top: 0;
	right: $grid-margin-gutters * 0.5;
	z-index: 3;
	display: flex;
	justify-content: center;
	align-items: center;
	height: $header-height;
	transition: opacity $duration;
	pointer-events: none;

	@include breakpoint(small) {
		right: $xs-value + $grid-margin-gutters * 0.5;
	}

	@include breakpoint(medium) {
		top: $sm-value + $xs-value;
		right: $grid-margin-gutters;
		height: auto;
	}

	@include breakpoint(large) {
		position: fixed;
		top: $m-value + $xs-value;
		right: $grid-margin-gutters * 0.5;
		transition: transform $fast-duration, opacity $fast-duration;
	}

	@include breakpoint(xlarge) {
		right: 120px + $grid-margin-gutters * 0.5;
	}

	@include breakpoint(xxxxxlarge) {
		right: calc($grid-margin-gutters * 0.5 + (100vw - 1920px) / 2);
	}

	&_button {
		@include fluid-type(320px, $grid-container, 14px, 22.5px);
		@include fallback(color, $secondary);
		overflow: hidden;
		padding: 0;
		border: none;
		outline: none;
		font-weight: 500;
		font-family: $font-family;
		line-height: 1em;
		text-transform: uppercase;
		background: transparent;
		pointer-events: auto;

		&::before {
			position: absolute;
			content: "";
			inset: -$xxs-value;

			@include breakpoint(large) {
				inset: -$xs-value;
			}
		}

		&:hover {
			.a-menuToggle_content {
				@include breakpoint(large) {
					background-size: 100% 0.1em;
				}

				&::before {
					@include breakpoint(large) {
						background-size: 100% 0.1em;
					}
				}
			}
		}
	}

	&_content {
		position: relative;
		display: block;
		line-height: 1.2em;
		transform: translateY(-100%);
		transition: transform $duration 0.37s, color $duration;

		@include breakpoint(large) {
			background: linear-gradient(to right, $secondary, $secondary);
			background-repeat: no-repeat;
			background-position: 100% 100%;
			background-size: 100% 0;
			cursor: pointer;
			transition: transform $duration 0.37s, background-size $fast-duration, color $duration;
		}

		&::before {
			@include fallback(color, $primary);
			position: absolute;
			top: 100%;
			right: 0;
			content: "Menu";

			@include breakpoint(large) {
				background: linear-gradient(to right, $primary, $primary);
				background-repeat: no-repeat;
				background-position: 100% 100%;
				background-size: 100% 0;
				cursor: pointer;
				transition: background-size $fast-duration;
			}
		}
	}
}

.o-body.-loading,
.o-body.-firstLoad {
	.a-menuToggle {
		opacity: 0;
	}
}

.o-body.-menuOn {
	.a-menuToggle_content {
		transform: translateY(0);
		transition: transform $duration, background-size $fast-duration, color $duration;
	}
}

.o-body.-ecoMode {
	.a-menuToggle_button {
		@include fallback(color, $primary);
	}

	.a-menuToggle_content {
		@include breakpoint(large) {
			background-image: linear-gradient(to right, $primary, $primary);
		}
	}
}

.o-body.-fastDisappear {
	.a-menuToggle_content {
		transform: translateY(-100%);
		transition: transform 0s;
	}
}

.o-body[data-scroll="down"] {
	.a-menuToggle {
		@include breakpoint(large) {
			transform: translateY(-100%);
			opacity: 0;
			pointer-events: none;
		}
	}

	.a-menuToggle_button {
		@include breakpoint(large) {
			pointer-events: none;
		}
	}
}

.o-body[data-scroll="fastUp"] {
	.a-menuToggle {
		@include breakpoint(large) {
			transition: transform 0s, opacity $fast-duration;
		}
	}
}
