.a-customerButton {
	display: none;

	@include breakpoint(xlarge) {
		@include fluid-type(320px, $grid-container, 14px, 16px);
		position: absolute;
		top: calc(100vh - 65px - $sm-value);
		right: $sm-value;
		z-index: 1;
		align-items: center;
		box-sizing: border-box;
		width: 180px;
		height: 65px;
		padding: 0 $s-value;
		padding: 0 20px;
		border-radius: 33px;
		font-weight: $weight-medium;
		font-family: $font-family;
		color: $secondary;
		text-decoration: none;
		background-color: $tertiary;
		animation: appear $fast-duration;
	}

	&_arrow {
		@include breakpoint(xlarge) {
			position: absolute;
			top: 50%;
			right: $s-value;
			width: 22px;
			height: 22px;
			transform: translateY(-50%);
			pointer-events: none;
		}

		&::before {
			@include breakpoint(xlarge) {
				position: absolute;
				border-radius: 50%;
				background: $secondary;
				transform: scale(0);
				transition: transform $faster-duration;
				content: "";
				inset: 0;
			}
		}

		svg {
			@include breakpoint(xlarge) {
				@include fallback(fill, $secondary);
				position: absolute;
				top: 50%;
				left: 50%;
				display: block;
				width: 8px;
				height: 8px;
				transform: translate(-50%, -50%);
				transition: fill $duration, transform $duration;
			}

			@include breakpoint(xlarge) {
				width: 10px;
				height: 10px;
			}
		}
	}

	&:hover,
	&:focus {
		.a-customerButton_arrow {
			&::before {
				@include breakpoint(xlarge) {
					transform: scale(1);
					transition: transform $duration $easeOutBack;
				}
			}

			svg {
				@include breakpoint(xlarge) {
					@include fallback(fill, $tertiary);
					// transform: translate(0, 0);
				}
			}
		}
	}
}

.o-body.-customer .a-customerButton {
	@include breakpoint(xlarge) {
		display: flex;
	}
}

@keyframes appear {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
