html {
	scroll-behavior: smooth;

	&.has-scroll-smooth {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		overflow: hidden;
	}
}

body {
	@include fluid-type(320px, $grid-container, 14px, 19px);
	margin-bottom: 0 !important;
	font-family: $font-family;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	color: $black;
	background-color: $white;
}

body.cke_ltr {
	.a-heading {
		color: $black;
	}
}

body:not(.cke_ltr) {
	@include fallback(color, $primary);
	@include fallback(background-color, $secondary);
}

.m-cookiziBanner,
.m-cookiziModal {
    color: $black;
}

::selection {
	color: $secondary;
	background: $primary;
}

img {
	display: block;
	max-width: 100%;
	height: auto;
}

strong {
	font-weight: 500;
}

em {
	font-style: italic;
}

// Lazy load
picture {
	display: block;
	overflow: hidden;

	img {
		width: 100%;
		margin: auto;
		// transform: scale(1.1);
		// filter: blur(3px);
		transition: filter $duration, transform $duration $easeOutCubic;

		&.loaded {
			// transform: scale(1);
			// filter: blur(0);
		}
	}
}

:focus {
	outline: none;
}
