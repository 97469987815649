.m-promoted {
	position: relative;

	@include breakpoint(large) {
		display: flex;
	}

	&:hover {
		.m-promoted_title {
			span {
				@include breakpoint(large) {
					background-size: 100% 0.1em;
				}
			}
		}

		img.loaded {
			@include breakpoint(large) {
				transform: scale(1.1);
				filter: grayscale(0);
			}
		}
	}

	&_left {
		@include breakpoint(large) {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			box-sizing: border-box;
			width: 50%;
			padding: $m-value $sm-value;
			color: $secondary;
			background-color: $primary;
		}

		@include breakpoint(xxlarge) {
			padding: $l-value 50px;
		}
	}

	&_right {
		@include breakpoint(large) {
			width: 50%;
		}

		&.-alreadyRead {
			img {
				@include breakpoint(large) {
					filter: grayscale(1);
				}
			}
		}

		img {
			@include breakpoint(large) {
				transition: transform $duration, filter $duration;
			}
		}
	}

	&_title {
		&.-mobile {
			@include fluid-type(320px, $grid-container, 20px, 32px);
			padding-right: $xs-value + $grid-margin-gutters * 0.5;
			margin-top: $s-value;

			@include breakpoint(small) {
				padding-right: $s-value + $grid-margin-gutters * 0.5;
			}

			@include breakpoint(medium) {
				padding-right: $xs-value + $grid-margin-gutters;
			}

			@include breakpoint(large) {
				display: none;
			}
		}

		&.-desktop {
			display: none;

			@include breakpoint(large) {
				@include fluid-type(320px, $grid-container, 40px, 60px);
				display: block;
				color: $secondary;
			}

			span {
				@include text-stroke(1, $primary);
				background: linear-gradient(to right, $secondary, $secondary);
				background-repeat: no-repeat;
				background-position: 100% 100%;
				background-size: 100% 0;
				transition: background-size $fast-duration;
			}
		}
	}

	&_top {
		@include fluid-type(320px, $grid-container, 12px, 14px);
		display: flex;
		margin-bottom: $sm-value * 0.5;
		font-weight: 500;
		line-height: 1.1em;
		letter-spacing: -0.05em;
		text-transform: uppercase;
	}

	&_topLeft {
		display: flex;
		align-items: center;
		width: 100%;
		padding-right: $xs-value + $grid-margin-gutters * 0.5;
		text-align: right;

		@include breakpoint(small) {
			padding-right: $s-value + $grid-margin-gutters * 0.5;
		}

		@include breakpoint(medium) {
			padding-right: $xs-value + $grid-margin-gutters;
		}

		@include breakpoint(large) {
			padding-right: 0;
		}
	}

	&_date {
		display: flex;
		align-items: center;

		&::after {
			display: inline-block;
			width: 13px;
			height: 1px;
			margin: 0 $xxs-value;
			background: $primary;
			content: "";

			@include breakpoint(large) {
				width: $s-value;
				margin: 0 $xs-value;
				background: $secondary;
			}
		}
	}

	&_read {
		position: relative;
		display: flex;
		margin-left: 8px;

		@include breakpoint(large) {
			color: $secondary;
		}

		&::before {
			position: absolute;
			top: -6px;
			right: -8px;
			bottom: -6px;
			left: -8px;
			border-radius: 20px;
			background: $primary;
			opacity: 0.3;
			content: "";

			@include breakpoint(large) {
				right: -$xs-value;
				left: -$xs-value;
				background: $secondary;
			}
		}

		svg {
			display: block;
			width: 10px;
			margin-left: $xxs-value;
			stroke: $primary;
			stroke-width: 2;

			@include breakpoint(large) {
				width: 12px;
				height: 11px;
				stroke: $secondary;
			}
		}
	}

	&_link {
		position: absolute;
		inset: 0;
	}
}
