.a-heading {
	font-weight: 500;
	color: $primary;
	line-height: 1.2em;

	&.-h1 {
		@include fluid-type(320px, $grid-container, 50px, 207px);
		padding-bottom: 0.15em; // Compensate line-height
		line-height: 1em;
		hyphens: auto;

		@include breakpoint(large) {
			hyphens: none;
		}

		&.-slim {
			@include fluid-type(320px, $grid-container, 40px, 110px);
			line-height: 1.1em;
		}

		&.-slimer {
			@include fluid-type(320px, $grid-container, 32px, 95px);
			line-height: 1.1em;
		}

		&.-mid {
			// @include fluid-type(320px, $grid-container, 46px, 186px);
			@include fluid-type(320px, $grid-container, 42px, 152px);
		}

		&.-big {
			line-height: 0.9em;

			@include breakpoint(large) {
				font-size: 175px;
			}

			@include breakpoint(xlarge) {
				@include fluid-type(1280px, $grid-container, 180px, 295px);
			}
		}
	}

	&.-h2 {
		@include fluid-type(320px, $grid-container, 34px, 85.5px);
		margin-bottom: 1em;
		line-height: 1.1em;

		&.-slim {
			@include fluid-type(320px, $grid-container, 26px, 57.5px);
		}

		&.-slimer {
			@include fluid-type(320px, $grid-container, 22px, 45px);
		}

		&.-notThatSlim {
			@include fluid-type(320px, $grid-container, 30px, 74px);
		}
	}

	&.-h3 {
		@include fluid-type(320px, $grid-container, 26px, 32px);
		margin-bottom: 1em;
		line-height: 1.1em;
	}

	&.-h4 {
		@include fluid-type(320px, $grid-container, 16px, 32px);
		line-height: 1.1em;
	}

	&.-h5 {
		@include fluid-type(320px, $grid-container, 14px, 24px);
		line-height: 1.1;
	}

	&.-white {
		color: #fff;
	}

	&.-primary {
		color: $primary;
	}

	&.-secondary {
		color: $secondary;
	}

	&.-tertiary {
		color: $tertiary;
	}

	&.-noTop {
		margin-top: 0;
	}

	&.-noBot {
		margin-bottom: 0;
	}

	&.-centered {
		text-align: center;
	}

	&_sub {
		display: block;
	}
}
