.a-teaser {
	box-sizing: border-box;
	padding: $l-value $sm-value;
	color: $secondary;
	background: $primary;

	@include breakpoint(medium) {
		padding: $xl-value $l-value;
	}

	@include breakpoint(large) {
		padding: $xxl-value $xl-value;
	}
}
