.m-textsImage {
	&_item {
		@include xy-grid;
		margin-right: $grid-margin-gutters * -0.5;
		margin-bottom: $xl-value;
		margin-left: $grid-margin-gutters * -0.5;

		@include breakpoint(large) {
			margin-bottom: $xxl-value;
		}

		@include breakpoint(xxlarge) {
			margin-bottom: $xxl-value + $l-value;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&_title {
		@include xy-cell(12);
		box-sizing: border-box;
		padding-right: $xs-value + $grid-margin-gutters * 0.5;
		margin-bottom: $sm-value;

		@include breakpoint(small) {
			padding-right: $s-value + $grid-margin-gutters * 0.5;
		}

		@include breakpoint(medium) {
			padding-right: $xs-value + $grid-margin-gutters;
		}

		@include breakpoint(large) {
			@include xy-cell(3);
			padding-right: 0;
			margin-bottom: 0;
		}
	}

	&_text {
		@include xy-cell(12);
		box-sizing: border-box;
		padding-right: $xs-value + $grid-margin-gutters * 0.5;

		@include breakpoint(small) {
			padding-right: $s-value + $grid-margin-gutters * 0.5;
		}

		@include breakpoint(medium) {
			padding-right: $xs-value + $grid-margin-gutters;
		}

		@include breakpoint(large) {
			@include xy-cell(6);
			padding-right: $l-value;
			padding-left: $l-value;
		}
	}

	&_placeholder {
		@include breakpoint(large) {
			@include xy-cell(3);
		}
	}
}
