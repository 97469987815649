.m-viewTeam {
	margin-right: $grid-margin-gutters * -0.5;
	margin-left: $grid-margin-gutters * -0.5;

	@include breakpoint(small) {
		margin-right: $grid-margin-gutters * -0.5 - $xs-value;
		margin-left: $grid-margin-gutters * -0.5 - $xs-value;
	}

	@include breakpoint(medium) {
		margin-right: -$grid-margin-gutters;
		margin-left: -$grid-margin-gutters;
	}

	@include breakpoint(large) {
		position: relative;
		margin-right: $grid-margin-gutters * -0.5;
		margin-left: $m-value - $grid-margin-gutters * 0.5;
	}

	.butter_slider {
		margin-right: $grid-margin-gutters * 0.5;
		margin-left: $grid-margin-gutters * 0.5;

		@include breakpoint(small) {
			margin-right: $grid-margin-gutters * 0.5 + $xs-value;
			margin-left: $grid-margin-gutters * 0.5 + $xs-value;
		}

		@include breakpoint(medium) {
			margin-right: $grid-margin-gutters;
			margin-left: $grid-margin-gutters;
		}
	}

	&_item {
		position: relative;
		z-index: 1;
		width: 210px;
		margin-right: $grid-margin-gutters;

		@include breakpoint(large) {
			@include xy-cell(12);
			display: flex;
			justify-content: space-between;
			align-items: center;
			box-sizing: border-box;
			max-width: none;
			padding-top: $xs-value;
			// padding-right: 100px;
			padding-right: $s-value;
			padding-bottom: $xs-value;
			padding-left: $s-value;
			transition: color $fast-duration;
		}

		&::before {
			@include breakpoint(large) {
				position: absolute;
				top: -$xxs-value;
				right: 0;
				bottom: -$xxs-value;
				left: 0;
				z-index: -1;
				background: $primary;
				transform: scaleY(0);
				transition: transform $fast-duration $easeInOutCubic;
				content: "";
			}
		}

		&:hover,
		&:focus {
			@include breakpoint(large) {
				z-index: 2;
				color: $secondary;
			}

			&::before {
				@include breakpoint(large) {
					transform: scaleY(100%);
				}
			}
		}

		&.-clone {
			@include breakpoint(large) {
				color: $secondary;
				opacity: 0;
				transition: opacity $fast-duration;
			}

			&.-hovered {
				@include breakpoint(large) {
					opacity: 1 !important;
				}
			}
		}
	}

	&_pic {
		margin-bottom: $sm-value;

		@include breakpoint(large) {
			position: absolute;
			visibility: hidden;
			pointer-events: none;
		}
	}

	&_name {
		@include fontSize(26px);
		margin-bottom: $xxs-value;
		font-weight: 500;

		@include breakpoint(large) {
			@include fluid-type(1024px, $grid-container, 36px, 44px);
			margin-bottom: 0;
			pointer-events: none;
		}
	}

	&_job {
		@include fontSize(14px);
		font-weight: 500;
		line-height: 1.3em;

		@include breakpoint(large) {
			@include fluid-type(1024px, $grid-container, 15px, 22px);
			pointer-events: none;
		}
	}

	&_audio {
		display: none;

		@include breakpoint(large) {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			display: block;
			width: 100px;
		}

		&.-playing {
			.m-viewTeam_play svg {
				opacity: 0;
			}

			.m-viewTeam_play svg:last-child {
				opacity: 1;
			}
		}

		audio {
			position: absolute;
			visibility: hidden;
			pointer-events: none;
		}
	}

	&_play {
		position: absolute;
		top: 50%;
		right: $s-value;
		box-sizing: border-box;
		width: 33px;
		height: 33px;
		padding: 0;
		border: none;
		background: none;
		transform: translateY(-50%);
		cursor: pointer;
		appearance: none;

		&.-playing {
			svg {
				opacity: 0;

				&:last-child {
					opacity: 1;
				}
			}
		}

		svg {
			width: 100%;
			height: 100%;
			transition: opacity $fasterr-duration;
			stroke: $secondary;

			&:last-child {
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0;
			}
		}
	}

	&_clones {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		width: 100%;
		pointer-events: none;
	}

	.advancedPointer {
		overflow: visible;
	}
}

.o-body.-eco {
	.m-viewTeam_item {
		@include breakpoint(large) {
			cursor: pointer;
		}

		&.-loaded {
			@include breakpoint(large) {
				cursor: initial;
			}
		}
	}
}
