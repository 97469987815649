.a-btn {
	$spacing: 1.5 * $xs-value;
	$pad-x: $spacing; //$m-value;
	$pad-y: $spacing; //12px;
	$pad-x-large: $spacing; //$m-value;
	$pad-y-large: $spacing; //18px;

	@include fluid-type(320px, $grid-container, 14px, 16px);
	display: inline-block;
	box-sizing: border-box;
	width: auto;
	padding: $pad-y $pad-x;
	border: none;
	border-radius: 3px;
	margin-top: $s-value;
	font-weight: $weight-normal;
	font-family: $font-family;
	color: $primary;
	text-align: center;
	text-decoration: none;
	background: $secondary;
	cursor: pointer;
	appearance: none;

	@include breakpoint(large) {
		padding: $pad-y-large $pad-x-large;
		border-radius: 5px;
	}

	@include breakpoint(xlarge) {
		transition: background-color $faster-duration;
	}

	&:hover,
	&:focus {
		@include breakpoint(large) {
			text-decoration: underline;
		}

		@include breakpoint(xlarge) {
			// border-color: $primary-light;
			// background-color: $primary-light;
		}
	}

	&.-secondary {
		color: $secondary;
		background-color: $primary;
	}
}
