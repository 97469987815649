@use "sass:math";

.m-gallery {
	@include xy-grid;
	margin-bottom: $grid-margin-gutters * -1;

	@include breakpoint(large) {
		margin-right: math.div($grid-margin-gutters, -2);
		margin-left: math.div($grid-margin-gutters, -2);
		margin-bottom: $xl-value * -1;
	}

	&_item {
		@include xy-cell(90%);

		margin-bottom: $grid-margin-gutters;

		@include breakpoint(medium) {
			@include xy-cell(6);
		}

		@include breakpoint(large) {
			margin-bottom: $xl-value;
		}
	}

	&_link {
		color: inherit;
		text-decoration: none;

		&:hover,
		&:focus,
		&:focus-within {
			.m-gallery_title {
				@include breakpoint(large) {
					text-decoration: underline;
				}
			}
		}

		.m-gallery_title {
			text-decoration: underline;

			@include breakpoint(large) {
				text-decoration: none;
			}
		}
	}

	&_image {
		position: relative;
		overflow: hidden;
		inset: 0;
		background: $grey;
	}

	&_title {
		@include fontSize(16px);

		margin-top: $xxs-value;
		line-height: 1.8;

		@include breakpoint(medium) {
			@include fontSize(18px);
		}

		@include breakpoint(large) {
			@include fontSize(20px);
		}
	}

	img {
		display: block;
		width: 100%;
	}
}
