.m-navOffers {
	&_item,
	&_subItem {
		margin-bottom: $xs-value;

		@include breakpoint(large) {
			margin-bottom: 12px;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&.-activeTrail {
			pointer-events: none;

			& > .m-navOffers_link,
			& > .m-navOffers_subLink {
				text-decoration: underline;
			}

			& > .m-navOffers_sub {
				pointer-events: auto;
			}
		}
	}

	&_link,
	&_subLink {
		@include fluid-type(320px, $grid-container, 14px, 20px);
		font-weight: 500;
		color: $primary;
		line-height: 1.1em;
		text-decoration: none;

		@include breakpoint(xlarge) {
			transition: color $faster-duration;
		}

		&:hover,
		&:focus {
			@include breakpoint(xlarge) {
				text-decoration: underline;
			}
		}
	}

	&_sub {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-top: $xs-value;
	}

	&_subLink {
		font-weight: 400;
	}
}
