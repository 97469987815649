.m-slider {
	margin-right: $grid-margin-gutters * -0.5;
	margin-left: $grid-margin-gutters * -0.5;

	@include breakpoint(small) {
		margin-right: $grid-margin-gutters * -0.5 - $xs-value;
		margin-left: $grid-margin-gutters * -0.5 - $xs-value;
	}

	@include breakpoint(medium) {
		margin-right: -$grid-margin-gutters;
		margin-left: -$grid-margin-gutters;
	}

	@include breakpoint(large) {
		margin-right: 0;
		margin-left: 0;
	}

	&.-visual {
		@include breakpoint(large) {
			background: $primary;
		}

		.swiper-container {
			&:nth-child(2) {
				.m-slider_item {
					background: transparent;
				}
			}
		}

		.m-slider_item {
			border: none;
			background: $primary;

			&:hover {
				span {
					@include breakpoint(large) {
						background-size: 100% 0.1em;
					}
				}

				img.loaded {
					@include breakpoint(large) {
						transform: scale(1.1);
					}
				}
			}
		}

		.m-slider_content {
			padding-top: $xxl-value + $s-value;
			color: $secondary;

			@include breakpoint(large) {
				padding: 220px calc(40% + $sm-value) $xl-value $sm-value;
			}

			@include breakpoint(xxlarge) {
				padding: 220px calc(40% + $m-value) 100px $m-value;
			}

			@include breakpoint(xxxlarge) {
				padding: 220px calc(40% + $l-value) 100px $l-value;
			}
		}
	}

	.butter_slider {
		padding-bottom: 0.5px;
		margin-right: $grid-margin-gutters * 0.5;
		margin-left: $grid-margin-gutters * 0.5;

		@include breakpoint(small) {
			margin-right: $grid-margin-gutters * 0.5 + $xs-value;
			margin-left: $grid-margin-gutters * 0.5 + $xs-value;
		}

		@include breakpoint(medium) {
			margin-right: $grid-margin-gutters;
			margin-left: $grid-margin-gutters;
		}

		@include breakpoint(large) {
			margin-right: 0;
			margin-left: 0;
		}
	}

	.swiper-container {
		overflow: hidden;

		@include breakpoint(large) {
			position: relative;
			z-index: 2;
		}

		&::before,
		&::after {
			@include breakpoint(large) {
				position: absolute;
				top: 0;
				bottom: 0;
				z-index: 2;
				width: 1px;
				background: $quaternary;
				content: "";
			}
		}

		&::after {
			@include breakpoint(large) {
				right: 0;
			}
		}

		&:nth-child(2) {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			overflow: visible;

			@include breakpoint(large) {
				z-index: 1;
			}

			.m-slider_counter {
				@include breakpoint(large) {
					color: transparent;
					-webkit-text-stroke-width: 1px;
					-webkit-text-stroke-color: $primary;
				}
			}
		}
	}

	&_item {
		position: relative;
		box-sizing: border-box;
		width: 210px;
		height: auto;
		border: 1px solid $quaternary;
		margin-right: $grid-margin-gutters;
		background: $secondary;

		@include breakpoint(medium) {
			width: 310px;
		}

		@include breakpoint(large) {
			width: 100%;
			border: none;
			margin-right: 0;
			background: transparent;
		}

		&:first-child {
			@include breakpoint(large) {
				display: block;
			}
		}

		&.swiper-slide-active {
			// .m-slider_counter {
			// 	@include breakpoint(large) {
			// 		color: $primary;
			// 	}
			// }

			.m-slider_title {
				@include breakpoint(large) {
					opacity: 1;
				}
			}

			.a-text {
				@include breakpoint(large) {
					opacity: 1;
				}
			}
		}
	}

	&_content {
		position: relative;
		z-index: 1;
		box-sizing: border-box;
		height: 100%;
		padding: $l-value $s-value $sm-value;

		@include breakpoint(large) {
			padding: 220px $m-value $xl-value $m-value;
		}

		@include breakpoint(xxlarge) {
			padding: 220px $l-value 100px $l-value;
		}

		@include breakpoint(xxxlarge) {
			padding: 220px $l-value 100px $l-value;
		}
	}

	&_counter {
		@include fluid-type(320px, $grid-container, 50px, 207px);
		margin-bottom: $s-value;
		font-weight: 500;

		@include breakpoint(large) {
			margin-bottom: $l-value;
		}
	}

	&_title {
		margin-bottom: $xs-value;
		font-weight: 600;
		font-family: $font-family;
		line-height: 1.2em;
		text-transform: uppercase;

		@include breakpoint(large) {
			margin-bottom: $sm-value;
		}

		span {
			@include text-stroke(1, $primary);
			background: linear-gradient(to right, $secondary, $secondary);
			background-repeat: no-repeat;
			background-position: 100% 100%;
			background-size: 100% 0;
			transition: background-size $fast-duration;
		}
	}

	.a-text {
		line-height: 1.3em;
	}

	&_link {
		position: absolute;
		inset: 0;
		z-index: 1;
	}

	picture {
		position: absolute;
		top: 0;
		width: 100%;
		height: $xxl-value;
		// opacity: 0.3;
		// filter: grayscale(50%) !important;

		@include breakpoint(large) {
			left: 60%;
			width: auto;
			width: 40%;
			height: 100%;
		}
	}

	img {
		position: absolute;
		object-fit: cover;
		width: 100%;
		height: 100%;

		@include breakpoint(large) {
			transition: transform $duration;
		}
	}

	&_arrow {
		@include breakpoint(large) {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			width: calc(25% + 0.9375rem);
		}

		@include breakpoint(xlarge) {
			right: -$xxl-value;
			width: calc(25% + 0.9375rem + $xxl-value);
		}

		&.-prev {
			@include breakpoint(large) {
				right: auto;
				left: 0;
			}

			@include breakpoint(xlarge) {
				left: -$xxl-value;
			}
		}

		&.-active {
			@include breakpoint(large) {
				cursor: pointer;
			}
		}
	}
}
