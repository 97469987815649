.m-worths {
	margin-right: $grid-margin-gutters * -0.5;
	margin-left: $grid-margin-gutters * -0.5;

	@include breakpoint(small) {
		margin-right: $grid-margin-gutters * -0.5 - $xs-value;
		margin-left: $grid-margin-gutters * -0.5 - $xs-value;
	}

	@include breakpoint(medium) {
		margin-right: -$grid-margin-gutters;
		margin-left: -$grid-margin-gutters;
	}

	@include breakpoint(large) {
		@include xy-grid;
		max-width: 1000px;
		margin-right: 0;
		margin-left: 0;
	}

	.butter_slider {
		margin-right: $grid-margin-gutters * 0.5;
		margin-left: $grid-margin-gutters * 0.5;

		@include breakpoint(small) {
			margin-right: $grid-margin-gutters * 0.5 + $xs-value;
			margin-left: $grid-margin-gutters * 0.5 + $xs-value;
		}

		@include breakpoint(medium) {
			margin-right: $grid-margin-gutters;
			margin-left: $grid-margin-gutters;
		}
	}

	&_item {
		box-sizing: border-box;
		width: 210px;
		padding: $sm-value;
		padding-bottom: $m-value;
		border-radius: 105px;
		margin-right: $grid-margin-gutters;
		background: $primary;

		@include breakpoint(large) {
			position: relative;
			z-index: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 360px;
			height: 360px;
			padding: $m-value $l-value;
			padding-bottom: $l-value;
			border-radius: 50%;
			margin-right: 0;
			margin-left: -$s-value;
			cursor: help;
			clip-path: circle(calc(50% + 1px) at 50% 50%);
		}

		@include breakpoint(xxxlarge) {
			width: 420px;
			height: 420px;
		}

		&::before {
			position: absolute;
			z-index: 2;
			border-radius: 50%;
			background: $primary;
			opacity: 0;
			transition: opacity $fast-duration;
			content: "";
			inset: 0;
		}

		&:first-child {
			@include breakpoint(large) {
				margin-left: 0;
			}
		}

		&:nth-child(3) {
			@include breakpoint(large) {
				margin-left: $xxl-value;
			}

			@include breakpoint(xxlarge) {
				margin-left: 180px;
			}
		}

		&:nth-child(3),
		&:nth-child(4) {
			@include breakpoint(large) {
				position: relative;
				z-index: 0;
				margin-top: -90px;
			}
		}

		&:hover,
		&:focus {
			@include breakpoint(large) {
				z-index: 3;
			}

			&::before {
				@include breakpoint(large) {
					opacity: 1;
				}
			}

			.m-worths_content {
				@include breakpoint(large) {
					opacity: 1;
				}
			}

			.m-worths_svg {
				@include breakpoint(large) {
					transform: scale(1);
					transition: transform $duration 0.1s $easeOutBack;
				}
			}
		}
	}

	&_content {
		font-weight: 500;
		color: $secondary;
		text-align: center;

		@include breakpoint(large) {
			position: relative;
			z-index: 2;
			opacity: 0;
			transition: opacity $fast-duration;
		}
	}

	&_svg {
		display: block;
		width: 52px;
		margin: auto;
		margin-bottom: 12px;
		fill: $secondary;

		@include breakpoint(large) {
			width: 78px;
			transform: scale(0);
			transition: transform 0s $fast-duration;
		}
	}

	&_title {
		@include fluid-type(320px, $grid-container, 16px, 28px);
		margin-bottom: $xs-value;
		font-weight: 500;
		line-height: 1.1em;
	}

	&_mask {
		display: none;

		@include breakpoint(large) {
			position: absolute;
			display: block;
			inset: 0;
		}
	}

	&_maskSvgBackground {
		@include breakpoint(large) {
			position: absolute;
			overflow: visible;
			fill: $secondary;
			stroke: $quaternary;
			inset: 0;
		}
	}

	&_maskSvg {
		@include breakpoint(large) {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 210px;
			height: 210px;
			transform: translate(-50%, -50%);
			transition: opacity $fast-duration;
			fill: $primary;
		}
	}
}
