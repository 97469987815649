.a-wrapper {
	@include xy-grid-container;
	box-sizing: border-box;

	@include breakpoint(large) {
		padding-right: 0;
		padding-left: 0;
	}

	@include breakpoint(xlarge) {
		width: calc(100% - 240px);
		margin-right: 120px;
		margin-left: 120px;
	}

	@include breakpoint(xxxxlarge) {
		margin-right: auto;
		margin-left: auto;
	}
}
