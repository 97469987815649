.o-header {
	height: $header-height;
	transition: border-color $duration, transform $duration $easeInOutCubic, opacity $duration;

	@include breakpoint(medium) {
		height: auto;
		padding-top: $sm-value;
		padding-bottom: $sm-value * 0.5;
		border-bottom: none;
	}

	@include breakpoint(large) {
		padding-top: $m-value;
	}

	&_container {
		@include xy-grid;
		align-items: center;
		height: 100%;
	}

	&_content {
		@include xy-cell(8);

		@include breakpoint(xlarge) {
			@include xy-cell(6);
		}
	}

	&_logo {
		@include fluid-type(320px, $grid-container, 24px, 49.5px);
		@include fallback(color, $primary);
		display: inline-block;
		font-weight: 500;
		line-height: 1.3em;
		letter-spacing: -0.02em;
		text-decoration: none;

		@include breakpoint(large) {
			line-height: 0.9em;
		}

		span {
			@include breakpoint(large) {
				display: block;
			}
		}
	}
}

.o-body.-loading,
.o-body.-firstLoad {
	.o-header {
		opacity: 0;
	}
}

.o-body.-home {
	.o-header_logo {
		@include breakpoint(large) {
			opacity: 0;
			pointer-events: none;
		}
	}
}
