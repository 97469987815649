.m-viewCustomercases {
	position: relative;
	width: 100%;

	&_rows {
		overflow: hidden;

		@include breakpoint(large) {
			padding-top: $xs-value;
			padding-bottom: $xs-value;
		}
	}

	&_row {
		@include xy-cell(12);
		position: relative;
		box-sizing: border-box;
		padding-top: $s-value;
		padding-right: $xs-value + $grid-margin-gutters * 0.5;
		padding-bottom: 25px + $s-value;

		@include breakpoint(large) {
			height: 150px;
			padding-top: 0;
			padding-right: 0;
			padding-bottom: 0;
		}

		&:hover,
		&:focus,
		&.-hovered {
			&::before {
				@include breakpoint(large) {
					display: block;
				}
			}

			.m-viewCustomercases_arrow::before {
				@include breakpoint(large) {
					transform: scale(1);
					transition: transform $duration $fast-duration $easeOutBack;
				}
			}

			.m-viewCustomercases_arrow svg {
				@include breakpoint(large) {
					transform: translate(0, 0);
					transition: fill $duration $fast-duration, transform $duration $fast-duration;
					fill: $secondary;
				}
			}
		}

		&.-clone {
			color: $secondary;

			@include breakpoint(large) {
				z-index: 1;
				width: 100%;
				margin-right: 0;
				margin-left: 0;
			}

			&::before {
				@include breakpoint(large) {
					position: absolute;
					top: -$xs-value;
					right: 0;
					bottom: -$xs-value;
					left: 0;
					background: $primary;
					transition: clip-path $duration $easeInOutCubic, background-color $duration $easeInOutCubic;
					content: "";
					clip-path: inset(50% 0% 50% 0%);
					will-change: clip-path;
				}
			}

			&.-hovered {
				&::before {
					@include breakpoint(large) {
						clip-path: inset(0% 0% 0% 0%);
					}
				}

				.m-viewCustomercases_clip {
					@include breakpoint(large) {
						clip-path: inset(0% 0% 0% 0%);
					}
				}

				.m-viewCustomercases_arrow svg {
					@include breakpoint(large) {
						fill: $primary;
					}
				}
			}

			.m-viewCustomercases_clip {
				@include breakpoint(large) {
					padding-right: $grid-margin-gutters * 0.5;
					padding-left: $grid-margin-gutters * 0.5;
					transition: clip-path $duration $easeInOutCubic;
					clip-path: inset(50% 0% 50% 0%);
				}
			}

			.a-heading {
				color: $secondary;
			}

			.a-heading::after {
				@include breakpoint(large) {
					background: $secondary;
				}
			}

			.m-viewCustomercases_arrow::before {
				@include breakpoint(large) {
					background: $secondary;
				}
			}

			.m-viewCustomercases_arrow svg {
				fill: $secondary;
			}

			.m-viewCustomercases_details {
				display: flex;
			}

			.m-viewCustomercases_detail::after {
				background: $secondary;
			}
		}
	}

	&_clip {
		height: 100%;
	}

	&_wrapper {
		@include breakpoint(large) {
			display: flex;
			align-items: center;
			height: 100%;
		}
	}

	&_title {
		position: relative;
		line-height: 1em;

		@include breakpoint(large) {
			padding-right: $m-value;
		}
	}

	&_arrow {
		display: none;

		@include breakpoint(large) {
			position: absolute;
			top: 0;
			right: 0;
			display: block;
			width: 22px;
			height: 22px;
			pointer-events: none;
		}

		&::before {
			@include breakpoint(large) {
				position: absolute;
				border-radius: 50%;
				background: $primary;
				transform: scale(0);
				transition: transform $faster-duration;
				content: "";
				inset: 0;
			}
		}

		svg {
			@include breakpoint(large) {
				@include fallback(fill, $primary);
				position: absolute;
				top: 6px;
				left: 6px;
				display: block;
				width: 8px;
				height: 8px;
				transition: fill $duration, transform $duration;
			}

			@include breakpoint(large) {
				width: 10px;
				height: 10px;
				transform: translate(-75%, 75%);
			}
		}
	}

	&_link {
		position: absolute;
		inset: 0;

		@include breakpoint(large) {
			top: -$xs-value;
			right: -$grid-margin-gutters * 0.5;
			bottom: -$xs-value;
			left: -$grid-margin-gutters * 0.5;
		}
	}

	&_details {
		@include fluid-type(320px, $grid-container, 12px, 16.5px);
		position: absolute;
		right: -$grid-margin-gutters * 0.5;
		bottom: $s-value;
		left: -$grid-margin-gutters * 0.5;
		display: none;
		width: 100%;
		font-weight: 500;
		line-height: 1.1em;
		text-transform: uppercase;
		white-space: nowrap;

		@include breakpoint(large) {
			bottom: 13px;
		}
	}

	&_detailsScroll {
		display: flex;
	}

	&_detail {
		position: relative;
		padding-right: 33px;

		@include breakpoint(large) {
			padding-right: 47px;
		}

		&::after {
			position: absolute;
			top: 50%;
			right: 10px;
			width: 13px;
			height: 1px;
			background: $primary;
			content: "";

			@include breakpoint(large) {
				right: 15px;
				width: 17px;
			}
		}
	}

	&_banner {
		position: absolute;
		visibility: hidden;
		pointer-events: none;
	}

	&_clones {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		overflow: hidden;
		background: $primary;
		clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
		pointer-events: none;
		will-change: clip-path;

		@include breakpoint(large) {
			padding-top: $xs-value;
			padding-bottom: $xs-value;
			background: transparent;
			clip-path: none;
		}
	}

	&_background {
		position: absolute;
		inset: 0;
		z-index: -1;

		@include breakpoint(large) {
			position: absolute;
			overflow: hidden;
		}
	}

	&_backgroundLift {
		position: sticky;
		top: 0;
		right: 0;
		left: 0;
		height: 100vh;
		max-height: 120vh;

		@include breakpoint(large) {
			position: absolute;
			top: -100vh;
			height: 100vh;
			max-height: none;
			// will-change: transform;
		}

		article {
			position: absolute;
			background: $secondary;
			opacity: 0;
			will-change: opacity;
			inset: 0;
		}

		img {
			position: absolute;
			object-fit: cover;
			width: 100%;
			height: 100%;
			opacity: 0.8;
			filter: grayscale(50%) !important;
		}
	}
}

.o-body.-eco {
	.m-viewCustomercases_background {
		display: none;
	}
}
