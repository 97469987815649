.o-footer {
	position: relative;

	@include breakpoint(large) {
		margin-top: $xxl-value + $l-value;
		transition: opacity 0s 1s;
	}

	&::before {
		position: absolute;
		top: -0.5px;
		right: 0;
		left: 0;
		height: 1px;
		background: $quaternary;
		content: "";

		@include breakpoint(large) {
			display: none;
		}
	}

	&_top {
		@include xy-grid;
		position: relative;
		padding-bottom: $m-value;

		@include breakpoint(large) {
			padding-bottom: $l-value;
		}
	}

	&_column {
		@include xy-cell(5);

		@include breakpoint(medium) {
			@include xy-cell(6);
		}

		@include breakpoint(large) {
			@include xy-cell(2);
		}

		&:first-child {
			@include xy-cell(12);
			position: relative;
			padding-top: $sm-value;
			padding-bottom: $sm-value;
			margin-bottom: $m-value;

			@include breakpoint(large) {
				@include xy-cell(2);
				@include xy-cell-offset(1);
				padding-top: 0;
				padding-bottom: 0;
				margin-bottom: 0;
			}

			&::after {
				position: absolute;
				right: -$grid-margin-gutters * 0.5;
				bottom: 0;
				left: -$grid-margin-gutters * 0.5;
				height: 1px;
				background: $quaternary;
				content: "";

				@include breakpoint(small) {
					right: -$xs-value - $grid-margin-gutters * 0.5;
					left: -$xs-value - $grid-margin-gutters * 0.5;
				}

				@include breakpoint(medium) {
					right: -$grid-margin-gutters;
					left: -$grid-margin-gutters;
				}

				@include breakpoint(large) {
					display: none;
				}
			}
		}

		&:nth-child(2) {
			@include breakpoint(large) {
				@include xy-cell-offset(2);
			}
		}

		&:nth-child(3) {
			@include breakpoint(large) {
				@include xy-cell-offset(3);
			}
		}
	}

	&_title {
		@include fluid-type(320px, $grid-container, 14px, 20px);
		margin-bottom: $xs-value;
		font-weight: 500;
	}

	.m-navSocial {
		opacity: 1;
	}

	.m-navSocial_item {
		display: block;
		margin-right: 0;
		margin-bottom: $xs-value;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.m-navSocial_link {
		@include fluid-type(320px, $grid-container, 14px, 20px);
		color: $primary;
	}

	&_lines {
		position: absolute;
		top: 0;
		right: $grid-margin-gutters * 0.5;
		bottom: 0;
		pointer-events: none;

		@include breakpoint(small) {
			right: $xs-value + $grid-margin-gutters * 0.5;
		}

		@include breakpoint(medium) {
			right: $grid-margin-gutters;
		}

		@include breakpoint(large) {
			top: -$xxl-value - $l-value;
			right: calc(25% + 0.9375rem);
			left: calc(25% + 0.9375rem);
		}
	}

	&_line {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: $s-value;

		@include breakpoint(large) {
			right: auto;
			transform: translateX(-50%);
		}

		&::before {
			position: absolute;
			right: 0;
			bottom: 0;
			left: 0.5px;
			height: 1px;
			background: $quaternary;
			content: "";

			@include breakpoint(large) {
				right: -0.5px;
				left: 0;
			}
		}

		&::after {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 50%;
			width: 1px;
			background: $quaternary;
			content: "";
		}

		&:last-child {
			display: none;

			@include breakpoint(large) {
				right: 0;
				left: auto;
				display: block;
				transform: translateX(50%);
			}

			&::before {
				@include breakpoint(large) {
					right: 0;
					left: -0.5px;
				}
			}

			&::after {
				@include breakpoint(large) {
					right: 50%;
					left: auto;
				}
			}
		}
	}

	&_bot {
		@include xy-grid;
		padding-top: $m-value;
		padding-bottom: $m-value;

		@include breakpoint(large) {
			padding-top: $xl-value;
			padding-bottom: $l-value;
		}
	}

	&_contacts {
		@include xy-cell(12);
		display: flex;
		justify-content: center;
		align-items: center;
		flex-flow: row wrap;
		margin-bottom: $m-value;

		@include breakpoint(large) {
			margin-bottom: $xl-value;
		}
	}

	&_contact {
		@include fluid-type(320px, $grid-container, 40px, 211.5px);
		font-weight: 500;
		color: $primary;
		line-height: 1.15em;
		text-decoration: none;

		&.-phone {
			@include fluid-type(320px, $grid-container, 32px, 172px);
		}
	}

	&_legal {
		@include xy-cell(12);
	}
}

.o-body.-loading {
	// Prevent footer blink when you navigate in footer
	.o-footer {
		@include breakpoint(large) {
			opacity: 0;
		}
	}
}

.o-body.-article {
	.o-footer {
		@include breakpoint(large) {
			position: relative;
			padding-top: $xxl-value + $l-value;
			margin-top: 0;
		}

		&::before {
			@include breakpoint(large) {
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				display: block;
				height: 1px;
				background: $quaternary;
				content: "";
			}
		}
	}
}
