.m-navSocial {
	margin-bottom: -8px;
	opacity: 0;
	transition: opacity $fast-duration;

	&_item {
		display: inline-block;
		margin-right: $xxs-value;
		margin-bottom: 8px;

		&:last-child {
			margin-right: 0;
		}
	}

	&_link {
		@include fluid-type(320px, $grid-container, 13px, 17.5px);
		color: $secondary;
		text-decoration: none;
		transition: color $duration;

		&:hover,
		&:focus {
			@include breakpoint(xlarge) {
				text-decoration: underline;
			}
		}
	}
}

.o-body.-menuOn {
	.m-navSocial {
		opacity: 1;
		transition: opacity $duration $long-duration;
	}
}

.o-body.-ecoMode {
	.m-navSocial_link {
		color: $primary;
	}
}
