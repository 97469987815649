@use "sass:math";

$grid-container: 1920px;
$grid-margin-gutters: 30px;

$breakpoints: (
	xsmall: 370px,
	small: 568px,
	medium: 768px,
	large: 1024px,
	xlarge: 1280px,
	xxlarge: 1366px,
	xxxlarge: 1600px,
	xxxxlarge: 1920px,
	xxxxxlarge: 2160px
);

@function calculateRem($size) {
	$remSize: math.div($size, 16px);

	@return #{$remSize}rem;
}

@mixin fontSize($size) {
	font-size: $size;
	font-size: calculaterem($size);
}
