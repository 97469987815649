.m-form {
	@include forFields {
		@include fluid-type(320px, $grid-container, 12px, 18px);
		box-sizing: border-box;
		width: 100%;
		padding: 12px;
		border: none;
		border: 1px solid $primary;
		font-weight: 400;
		font-family: $font-family-alt;
		color: $primary;
		background: transparent;
		appearance: none;

		@include breakpoint(large) {
			padding: 18px;
		}

		&::placeholder {
			color: $primary;
			opacity: 0.3;
		}
	}
	// max-width: 470px;

	&.-top {
		margin-top: $sm-value;

		@include breakpoint(large) {
			margin-top: $l-value;
		}
	}

	label {
		@include fluid-type(320px, $grid-container, 12px, 20px);
		display: block;
		margin-bottom: $xxs-value;
		font-weight: 500;
		color: $primary;
		line-height: 1.2em;

		@include breakpoint(large) {
			margin-bottom: $xs-value;
		}

		a {
			color: $primary;
		}

		&.button {
			text-decoration: underline;

			@include breakpoint(large) {
				cursor: pointer;
			}
		}
	}

	.form-item {
		margin-bottom: $sm-value * 0.5;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.form-item-rgpd {
		margin-top: $sm-value;

		label {
			@include fluid-type(320px, $grid-container, 12px, 16px);
			display: inline;
			margin-bottom: 0;
		}
	}

	input[type="checkbox"],
	input[type="radio"] {
		position: relative;
		width: 15px;
		height: 15px;
		border: solid 1px $primary;
		outline: none;
		margin: 0;
		margin-top: -3px;
		margin-right: $xxs-value;
		color: $secondary;
		vertical-align: middle;
		cursor: pointer;
		appearance: none;

		&:checked {
			background: $primary;

			&::before {
				display: block;
			}
		}

		&::before {
			@include fontSize(12px);
			position: absolute;
			top: 50%;
			left: 50%;
			display: none;
			transform: translate(-50%, -50%);
			content: "✔";
		}
	}

	input[type="radio"] {
		border-radius: 50%;
	}

	.webform-button--submit-container {
		position: relative;
		display: block;
		margin-top: $s-value;

		@include breakpoint(large) {
			margin-top: $m-value;
		}

		svg {
			position: absolute;
			top: 50%;
			right: $s-value;
			width: 0.75em;
			height: 0.75em;
			transform: translateY(-50%);
			fill: $secondary;
		}
	}

	input[type=submit] {
		@include fluid-type(320px, $grid-container, 14px, 26px);
		box-sizing: border-box;
		width: 100%;
		padding: 12px $m-value;
		border: none;
		border-radius: 3px;
		font-weight: 500;
		font-family: $font-family;
		color: $secondary;
		text-align: center;
		text-decoration: none;
		background: $primary;
		cursor: pointer;
		appearance: none;

		@include breakpoint(large) {
			padding: 18px $m-value;
			border-radius: 5px;
		}

		&:hover {
			@include breakpoint(large) {
				text-decoration: underline;
			}
		}
	}

	.form-item--error-message {
		@include fontSize(12px);
		margin-top: $xs-value;
		color: $error;
		line-height: 1.3em;

		a {
			color: $black;
		}
	}

	.description,
	.field-rgpd-text {
		@include fontSize(12px);
		margin-top: $xs-value;
		color: $grey-dark;
		line-height: 1.5em;

		a {
			color: $black;
		}
	}

	.contentinfo {
		display: none;
	}

	.js-webform-document-file {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: $xs-value;
		border: 1px dashed $primary;

		@include breakpoint(large) {
			padding: $xs-value $sm-value * 0.5;
		}

		input[type=file] {
			width: calc(100% - 80px);

			@include breakpoint(large) {
				width: auto;
			}
		}

		.webform-button--submit-container {
			margin-top: 0;
		}

		input[type=submit] {
			@include fluid-type(320px, $grid-container, 12px, 16px);
			width: 80px;
			padding: 7px $xs-value;
			font-size: 14px;

			@include breakpoint(large) {
				width: auto;
			}
		}

		svg {
			display: none;
		}

		a {
			@include fluid-type(320px, $grid-container, 12px, 20px);
			display: inline-block;
			font-weight: 500;
			color: $primary;
		}
	}
}
