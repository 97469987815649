$font-family: "TT Norms", sans-serif;
$font-family-alt: "Poppins", sans-serif;

$primary-fallback: #d9d9d9;
$primary: var(--primary, $primary-fallback);
$secondary-fallback: #121212;
$secondary: var(--secondary, $secondary-fallback);
$tertiary-fallback: #4361ee;
$tertiary: var(--tertiary, $tertiary-fallback);
$quaternary-fallback: #d9d9d9;
$quaternary: var(--quaternary, $quaternary-fallback);
$black: #121212;
$black-light: #444;
$grey: #f2f2f2;
$grey-dark: #ccc;
$swpl: #041bb9;
$swpl-dark: darken($swpl, 10%);
$white: #d9d9d9;
$real-white: #fff;

$valid: #4caf50;
$error: #de3b2f;

$xxs-value: 5px;
$xs-value: 10px;
$s-value: 20px;
$sm-value: 30px;
$m-value: 40px;
$l-value: 60px;
$xl-value: 80px;
$xxl-value: 120px;

$header-height: 60px;
$header-height-large: 170px;

$fasterr-duration: 0.1s;
$faster-duration: 0.15s;
$fast-duration: 0.25s;
$duration: 0.5s;
$long-duration: 0.75s;
$longer-duration: 1s;

$easeInCubic: cubic-bezier(0.32, 0, 0.67, 0);
$easeOutCubic: cubic-bezier(0.33, 1, 0.68, 1);
$easeInOutCubic: cubic-bezier(0.66, 0, 0.34, 1);
$easeOutBack: cubic-bezier(0.34, 1.56, 0.64, 1);

$weight-thin: 100;
$weight-xlight: 200;
$weight-light: 300;
$weight-normal: 400;
$weight-medium: 500;
$weight-sbold: 600;
$weight-bold: 700;
$weight-xbold: 800;
$weight-black: 900;

$base-font-size: 16px;
$weight-regular: $weight-normal;
