.butter {
	overflow: hidden;
	width: 100%;

	&_slider {
		display: flex;
		overflow: visible;
		will-change: transform;
	}

	&_slide {
		flex: 0 0 auto;
		user-select: none;
	}

	&_progress {
		position: absolute;
		bottom: -$sm-value;
		width: 100%;
		height: 1px;
	}

	&_bar {
		position: absolute;
		top: 0;
		left: 0;
		width: 0%;
		height: 1px;
		background-color: $quaternary;
	}
}
