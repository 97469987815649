.m-viewArticle {
	&_rows {
		@include breakpoint(large) {
			@include xy-grid;
			align-items: flex-start;
			margin-right: $grid-margin-gutters * -0.5;
			margin-left: $grid-margin-gutters * -0.5;
		}
	}

	&_row {
		position: relative;
		display: flex;
		box-sizing: border-box;
		margin-bottom: $l-value;

		@include breakpoint(large) {
			@include xy-cell(4);
			min-height: 1px;
			padding-right: 0;
			margin-bottom: 0;
			pointer-events: none;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&:hover {
			.m-viewArticle_title {
				span {
					@include breakpoint(large) {
						background-size: 100% 0.1em;
					}
				}
			}

			.m-viewArticle_visual img.loaded {
				@include breakpoint(large) {
					transform: scale(1.1);
					filter: grayscale(0);
				}
			}
		}
	}

	&_left {
		box-sizing: border-box;
		width: 60%;
		padding-right: $xs-value;

		@include breakpoint(large) {
			width: 100%;
			padding-right: 0;
		}
	}

	&_top {
		@include fluid-type(320px, $grid-container, 11px, 15px);
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: $sm-value * 0.5;
		font-weight: 500;
		line-height: 1.1em;
		letter-spacing: -0.05em;
		text-transform: uppercase;

		@include breakpoint(large) {
			margin-bottom: $s-value;
		}
	}

	&_topLeft {
		display: flex;
		align-items: center;
		flex-flow: row wrap;
		width: 100%;
		padding-right: $xs-value + $grid-margin-gutters * 0.5;

		@include breakpoint(small) {
			padding-right: $s-value + $grid-margin-gutters * 0.5;
		}

		@include breakpoint(medium) {
			padding-right: $xs-value + $grid-margin-gutters;
		}

		@include breakpoint(large) {
			padding-right: 0;
		}
	}

	&_date {
		display: flex;
		align-items: center;

		&::after {
			display: inline-block;
			width: 13px;
			height: 1px;
			margin: 0 $xxs-value;
			background: $primary;
			content: "";

			@include breakpoint(large) {
				width: 8px;
				margin: 0 8px;
			}

			@include breakpoint(xxlarge) {
				width: $s-value;
				margin: 0 $xs-value;
			}
		}
	}

	&_read {
		position: relative;
		display: flex;
		margin-left: 8px;

		&::after {
			position: absolute;
			top: -6px;
			right: -8px;
			bottom: -6px;
			left: -8px;
			border-radius: 20px;
			background: $primary;
			opacity: 0.3;
			content: "";

			@include breakpoint(large) {
				right: -$xs-value;
				left: -$xs-value;
			}
		}

		svg {
			display: block;
			width: 10px;
			margin-left: $xxs-value;
			stroke: $primary;
			stroke-width: 2;

			@include breakpoint(large) {
				width: 12px;
				height: 11px;
				transform: translateY(1px);
			}
		}
	}

	&_visual {
		position: absolute;
		top: 0;
		right: 0;
		width: 40%;
		background: $secondary;

		@include breakpoint(large) {
			position: static;
			overflow: hidden;
			width: auto;
		}

		&.-alreadyRead {
			img {
				@include breakpoint(large) {
					// opacity: 0.6;
					filter: grayscale(1);
				}
			}
		}

		img {
			@include breakpoint(large) {
				transition: transform $duration, filter $duration;
			}
		}
	}

	&_title {
		@include fluid-type(320px, $grid-container, 16px, 33px);
		margin-top: $s-value;

		span {
			@include text-stroke(1, $secondary);
			background: linear-gradient(to right, $primary, $primary);
			background-repeat: no-repeat;
			background-position: 100% 100%;
			background-size: 100% 0;
			transition: background-size $fast-duration;
		}
	}

	&_link {
		position: absolute;
		inset: 0;

		@include breakpoint(large) {
			pointer-events: auto;
		}
	}

	&_placeholder {
		position: relative;
		width: 40%;

		@include breakpoint(large) {
			display: none;
		}

		&::before {
			display: block;
			width: 100%;
			padding-bottom: 67%;
			content: "";
		}
	}

	&_more {
		display: flex;
		justify-content: center;
		box-sizing: border-box;
		padding-right: $xs-value + $grid-margin-gutters * 0.5;
		margin-top: $l-value;
		transition: opacity $faster-duration;

		@include breakpoint(small) {
			padding-right: $s-value + $grid-margin-gutters * 0.5;
		}

		@include breakpoint(medium) {
			padding-right: $xs-value + $grid-margin-gutters;
		}

		@include breakpoint(large) {
			padding-right: 0;
			margin-top: 0;
			margin-bottom: $xl-value;
		}

		&.-loading {
			pointer-events: none;

			button {
				opacity: 0.5;
				animation: rotating 0.75s linear infinite;
			}
		}

		&.-inactive {
			display: none;
		}

		button {
			@include fluid-type(320px, $grid-container, 14px, 25px);
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			box-sizing: border-box;
			width: 70px;
			height: 70px;
			padding: $xs-value;
			border: none;
			font-weight: 500;
			font-family: $font-family;
			color: $primary;
			text-align: center;
			text-decoration: none;
			background: transparent;
			cursor: pointer;
			appearance: none;

			@include breakpoint(large) {
				width: 130px;
				height: 130px;
				padding: $s-value;
			}

			&::before {
				position: absolute;
				border: 1px solid $primary;
				border-radius: 50%;
				content: "";
				inset: 0;

				@include breakpoint(large) {
					transition: transform $fast-duration;
				}
			}

			&:hover {
				@include breakpoint(large) {
					text-decoration: underline;
				}
			}
		}
	}
}

.o-block.-viewArticles {
	.m-viewArticle_row {
		@include breakpoint(large) {
			padding-top: $xl-value;
			padding-bottom: $xl-value;
		}

		&:nth-child(2n+1) {
			@include breakpoint(large) {
				@include xy-cell(6);
				@include xy-cell-offset(3);
				box-sizing: border-box;
				padding-right: $sm-value;
				padding-left: $sm-value;
				border-top: $quaternary 1px solid;
			}

			// &:not(:first-child) {
			// 	@include breakpoint(large) {
			// 		border-bottom: $quaternary 1px solid;
			// 	}
			// }

			.m-viewArticle_link {
				@include breakpoint(large) {
					right: $sm-value;
					left: $sm-value;
				}
			}
		}

		&:nth-child(2n+2) {
			@include breakpoint(large) {
				@include xy-cell(3);
			}
		}

		&:first-child {
			@include breakpoint(large) {
				border-top: none;
			}
		}

		.m-viewArticle_link {
			@include breakpoint(large) {
				top: $xl-value;
				bottom: $xl-value;
			}
		}
	}
}

@keyframes rotating {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}
