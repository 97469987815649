.m-viewJobs {
	&_row {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		box-sizing: border-box;
		padding-top: $s-value;
		padding-right: $xs-value + $grid-margin-gutters * 0.5;
		padding-bottom: $s-value;

		@include breakpoint(small) {
			padding-right: $s-value + $grid-margin-gutters * 0.5;
		}

		@include breakpoint(medium) {
			padding-right: $xs-value + $grid-margin-gutters;
		}

		@include breakpoint(large) {
			padding-top: $m-value;
			padding-right: 0;
			padding-bottom: $m-value;
			padding-left: $l-value;
			background-image: linear-gradient(to right, transparent 50%, $secondary 50%);
		}

		&::before {
			position: absolute;
			top: 0;
			right: $xs-value;
			left: -$grid-margin-gutters * 0.5;
			height: 1px;
			background: $quaternary;
			content: "";

			@include breakpoint(small) {
				left: -$grid-margin-gutters * 0.5 - $xs-value;
			}

			@include breakpoint(medium) {
				left: -$grid-margin-gutters;
			}

			@include breakpoint(large) {
				right: -$grid-margin-gutters * 0.5;
				left: 0;
			}

			@include breakpoint(xlarge) {
				right: -$grid-margin-gutters * 0.5 -$xxl-value;
			}
		}

		&:last-child {
			&::after {
				position: absolute;
				right: $xs-value;
				bottom: 0;
				left: -$grid-margin-gutters * 0.5;
				height: 1px;
				background: $quaternary;
				content: "";

				@include breakpoint(small) {
					left: -$grid-margin-gutters * 0.5 - $xs-value;
				}

				@include breakpoint(medium) {
					left: -$grid-margin-gutters;
				}

				@include breakpoint(large) {
					right: -$grid-margin-gutters * 0.5;
					left: 0;
				}

				@include breakpoint(xlarge) {
					right: -$grid-margin-gutters * 0.5 -$xxl-value;
				}
			}
		}

		&:hover,
		&:focus {
			.m-viewJobs_arrow {
				&::before {
					@include breakpoint(xlarge) {
						transform: scale(1);
						transition: transform $duration $easeOutBack;
					}
				}

				svg {
					@include breakpoint(xlarge) {
						@include fallback(fill, $secondary);
						transform: translate(0, 0);
					}
				}
			}
		}
	}

	&_type {
		position: relative;
		display: inline-block;
		padding: 3px $sm-value * 0.5;
		padding-left: $sm-value;
		margin-bottom: $sm-value * 0.5;
		font-weight: 500;
		color: $secondary;
		background: $primary;

		@include breakpoint(large) {
			margin-bottom: $s-value;
		}

		&::before {
			position: absolute;
			top: 50%;
			left: $xs-value;
			width: 9px;
			height: 9px;
			border-radius: 50%;
			background: $secondary;
			transform: translateY(-50%);
			content: "";
		}
	}

	&_title {
		@include fluid-type(320px, $grid-container, 20px, 56px);
		position: relative;
		display: inline-block;
		margin-bottom: $s-value;
		font-weight: 500;
		line-height: 1em;

		@include breakpoint(large) {
			padding-right: $m-value;
		}
	}

	&_arrow {
		display: none;

		@include breakpoint(large) {
			position: absolute;
			top: 0;
			right: 0;
			display: block;
			width: 22px;
			height: 22px;
			pointer-events: none;
		}

		&::before {
			@include breakpoint(large) {
				position: absolute;
				border-radius: 50%;
				background: $primary;
				transform: scale(0);
				transition: transform $faster-duration;
				content: "";
				inset: 0;
			}
		}

		svg {
			@include breakpoint(large) {
				@include fallback(fill, $primary);
				position: absolute;
				top: 6px;
				left: 6px;
				display: block;
				width: 8px;
				height: 8px;
				transition: fill $duration, transform $duration;
			}

			@include breakpoint(xlarge) {
				width: 10px;
				height: 10px;
				transform: translate(-75%, 75%);
			}
		}
	}

	&_info {
		font-weight: 500;

		span {
			margin-left: $sm-value;
		}
	}

	&_link {
		position: absolute;
		top: 0;
		right: $xs-value;
		bottom: 0;
		left: -$grid-margin-gutters * 0.5;

		@include breakpoint(small) {
			left: -$grid-margin-gutters * 0.5 - $xs-value;
		}

		@include breakpoint(medium) {
			left: -$grid-margin-gutters;
		}

		@include breakpoint(large) {
			right: -$grid-margin-gutters * 0.5;
			left: 0;
		}

		@include breakpoint(xlarge) {
			right: -$grid-margin-gutters * 0.5 -$xxl-value;
		}
	}
}
